<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card
      class="join-game__dialog">
      <v-toolbar :color="options.color" dense flat>
        <v-toolbar-title >
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <section class="row mx-0 my-4 px-6 flex-column">
        <p class="form-label">Host Account</p>
        <p class="form-value host">{{host}}</p>
        <p v-if="hostName && (hostName !== host)" class="form-value" style="line-height:0">({{hostName}})</p>
        <p class="form-label">Amount commited to game contract</p>
        <p class="form-value ether">{{amountEth}} ETH</p>
        <p v-if="this.gameFee" class="form-label">Game Fee ({{this.gameFeeStr}})</p>
        <p v-else class="form-label">Game Fee</p>
        <p class="form-value ether">{{estimatedGameFee}} ETH</p>
        <p class="form-label">Turn Expiration</p>
        <p class="form-value host">{{turnTimeoutStr}}</p>
      </section>
      <div class="px-4 px-sm-8">
        <div>
          <input type="checkbox" id="create-game__terms" value="true" v-model="agreeToTerms" >
          <span class="disclaimer">&nbsp;&nbsp;I have reviewed the <router-link to="/rules">rules</router-link> and agree that by creating this game I am risking the loss of some or all of my Table Stakes.</span>
        </div>
      </div>
      <v-card-actions class="pt-3">
        <button class="col-4 py-6 btn-cancel" @click="cancel">Cancel</button>
        <button
          class="flex-grow py-6 col-8 btn-submit"
          :disabled="!agreeToTerms"
          @click="agree" >
            Join Game</button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { NULL_ADDRESS, toBN, fromWei, toWei, isAddress } from '@/lib/ether';

import ms from 'ms';
export default {
  name: 'JoinGameDialog',
  computed: {
    turnTimeoutStr: function () {
      return this.turnTimeout && ms(this.turnTimeout * 1000, { long: true });
    },
    amountEth: function () {
      return this.amount ? fromWei(this.amount) : 0;
    },
    gameFeeStr: function () {
      const adj = (isAddress(this.addrWhite) && (this.addrWhite !== NULL_ADDRESS))
        ? 2
        : 1;
      return `${this.gameFee / adj}%`;
    },
    estimatedGameFee: function () {
      if ((!this.gameFee) || (!this.amount)) {
        return 0;
      }
      const amt = toBN(toWei(this.amount.toString()));
      const feePct = toBN((100 * this.gameFee.toString()));
      const adj = (isAddress(this.addrWhite) && (this.addrWhite !== NULL_ADDRESS))
        ? '2'
        : '1';
      return fromWei(amt.mul(feePct).div(toBN('10000')).div(toBN(adj)));
    }
  },
  data () {
    return {
      dialog: false,
      agreeToTerms: false,
      resolve: null,
      reject: null,
      amount: null,
      gameFee: null,
      turnTimeout: null,
      host: null,
      hostName: null,
      title: 'Join Game',
      options: {
        width: 600,
        zIndex: 200,
        noconfirm: false
      }
    };
  },
  watch: {
    agreeToTerms: function (v) {
      this.$emit('valid', v);
    }
  },
  methods: {
    open (game, options) {
      this.agreeToTerms = false;
      this.host = game.host;
      this.hostName = game.hostName;
      this.amount = game.amount;
      this.gameFee = Number(game.gameFee);
      this.turnTimeout = game.turnTimeout;
      this.dialog = true;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree () {
      this.resolve(true);
      this.dialog = false;
    },
    cancel () {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
<style lang="sass" scoped>
  @import '@/assets/css/variables.sass'

  .join-game__dialog .v-input__slot
    align-items: start

  .form-value
    font-family: RobotoMono
    font-size: 0.75rem
    color: black
    line-height: 1rem

  .form-label
    font-family: RobotoMono
    margin-top: 0
    margin-bottom: 0

  .disclaimer
    font-family: RobotoMono
    font-weight: 700
    color: white
    line-height: 1rem

  .host
    word-break: break-all

  .btn-submit
    background-color: $app-accent
  .btn-cancel
    background-color: $app-accent-dark

  .btn-submit:disabled,
  .btn-submit[disabled]
    color: $app-accent-dark
</style>
