<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :style="{ zIndex: options.zIndex }"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar dense flat>
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pa-4 form-hint"
        v-html="message"
      ></v-card-text>
      <v-card-actions class="pt-3">
        <button class="col-3 py-6 btn-cancel" @click="cancel">Cancel</button>
        <button
          class="flex-grow py-6 col-9 btn-submit"
          @click="agree" >
            End Game</button>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'ConfirmDlg',
  data () {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        color: 'grey lighten-3',
        width: 400,
        zIndex: 200,
        noconfirm: false
      }
    };
  },
  methods: {
    open (title, message, options) {
      this.dialog = true;
      this.title = title;
      this.message = message;
      this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    agree () {
      this.resolve(true);
      this.dialog = false;
    },
    cancel () {
      this.resolve(false);
      this.dialog = false;
    }
  }
};
</script>
<style lang="sass" scoped>
  @import '@/assets/css/variables.sass'
  .btn-submit
    background-color: $app-accent
  .btn-cancel
    background-color: $app-accent-dark
  .form-hint
    font-family: RobotoMono
    font-size: 0.75rem
    color: black
    line-height: 1rem
</style>
