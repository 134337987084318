<template>
  <div class="game-page">
    <page-header :amount="game.amount" />
    <div class="game-page__body d-flex flex-column mx-auto mt-1 mt-sm-8 ">
      <v-snackbar top light v-model="snackbar">{{snackbarText}}</v-snackbar>
      <join-game-dialog
        ref="joinGameDialog"
      ></join-game-dialog>
      <confirm-dialog ref="confirm" />
        <action-pending-dialog ref="actionPending">
          <template v-slot:message>
            <p>{{pendingAction.message}}<br/>
            Transaction Hash:
            <a v-if="etherScanTxUrl" target="_blank" :href="`${etherScanTxUrl}/${pendingAction.transactionHash}`">
            {{pendingAction.transactionHash | hash}}</a>
            <span v-else>{{pendingAction.transactionHash | hash}}</span>
            </p>
            <div class="d-flex">
              <div></div>
              <div><button @click="$refs.actionPending.close()">Close</button></div>
            </div>
          </template>
        </action-pending-dialog>
      <div class="d-flex px-4" style="max-width: 1280px">
        <div class="flex-grow-1">
          <div class="board-container" :class="turnClasses">
            <chessboard
              ref="chessboard"
              :fen="game.board"
              :enableSide="enableSide"
              :disabled="disabled"
              @onMove="onMove"
            />
          </div>
          <div v-if="showJoin" class="d-flex mt-8">
            <div class="button-bar mx-auto">
              <button
                class="btn-submit pa-6 col-12"
                @click="onCta({ action: 'join', game})"
                >Join Game</button>
            </div>
          </div>
          <div v-else-if="canClaimTrophy" class="mt-8 mb-8">
            <div class="button-bar mx-auto">
              <button
                class="btn-submit pa-6 col-12"
                @click="onCta({ action: 'claim', game})"
                >You Won, claim your Trophy!!!</button>
            </div>
          </div>
          <div v-else-if="canShowTrophy" class="mt-8 mb-8">
            <a class="d-block text-center" :href="trophyAddress" target="_blank">
              You Won, view your Trophy
            </a>
          </div>                
          <div v-else-if="game.gameOver" class="mt-8 mb-8">
            <p class="mx-auto my-0 text-center">{{describeGame.state}}</p>
            <p class="mx-auto my-0 text-center">{{describeGame.detail}}</p>
          </div>
          <div v-else-if="enableSide" class="mt-8">
            <div class="button-bar d-flex justify-sm-space-between mx-auto">
              <div class="button-bar__group col-8">
                <button class="col-4 btn-cancel"
                  :disabled="!pendingMove"
                  @click="undoMove">Undo Move
                </button>
                <button
                  class="flex-grow col-8 btn-submit"
                  :class="{pending: !!pendingMove}"
                  :disabled="!pendingMove"
                  @click="submitMove" >
                    Commit Move</button>
              </div>
              <div class="button-bar__group col-4">
                <button
                  class="btn-cancel col-sm-12"
                  @click="endGame(game)" >
                    {{endGameText}}</button>
              </div>
            </div>
          </div>
          <div v-else class="py-4" />
        </div>
        <div class="align-center scoreboard-full">
          <div class="flex-grow-1">
            <div class="d-flex flex-column my-0">
              <div class="d-flex">
                <div class="flex-grow-0 turn-pointer pa-0">{{turnPointer}}</div>
                <div class="flex-grow-0 turn-role"></div>
                <div class="px-0 turn-address">
                  <holding-pen :fen="game.board" side="b" />
                </div>
              </div>
              <div class="board-side__host d-flex mx-0" :class="{active: game.black === game.turn}">
                <div class="flex-grow-0 pa-0 turn-pointer host">{{turnPointer}}</div>
                <div class="flex-grow-0 py-0 ml-2 turn-role host">HOST</div>
                <div class="pa-0 turn-address host">{{blackAcct}}</div>
              </div>
              <div class="board-side__guest d-flex mx-0" :class="{active: game.white === game.turn}">
                <div class="flex-grow-0 pa-0 turn-pointer guest">{{turnPointer}}</div>
                <div class="flex-grow-0 py-0 ml-2 turn-role guest">GUEST</div>
                <div class="pa-0 turn-address guest">{{whiteAcct}}</div>
              </div>
              <div class="d-flex">
                <div class="flex-grow-0 turn-pointer pa-0">{{turnPointer}}</div>
                <div class="flex-grow-0 turn-role"></div>
                <div class="px-0">
                  <holding-pen :fen="game.board" side="w" />
                </div>
              </div>
            </div>
          </div>
          <div v-if="liveGame" class="flex-grow-0 ml-2 lastmove-full__wrap" >
            <button class="btn-showlastmove" :disabled ="pendingMove"  @click="showLastMove">
              <img src="@/assets/img/lastmove-button.svg" />
            </button>
          </div>
        </div>
      </div>
      <div v-if="liveGame" class="lastmove-compressed__wrap justify-center py-1">
        <button class="btn-showlastmove" :disabled ="pendingMove" @click="showLastMove">
          <img src="@/assets/img/lastmove-button.svg" />
        </button>
      </div>
      <div class="d-flex mb-2 clock-wrapper justify-space-around">
        <div class="ml-2 px-0 scoreboard-compressed ">
            <div class="board-side__host d-flex ma-0 pa-0 justify-space-between" :class="{active: game.black === game.turn}">
              <div class="flex-grow-0 pa-0 turn-role host align">HOST</div>
              <div class="flex-grow-0 pa-0 turn-pointer host">{{turnPointerHost}}</div>
            </div>
            <div class="board-side__host d-flex mx-0">
              <div class="pa-0 turn-address host">{{blackAcct}}</div>
            </div>
            <div class="board-side__host d-flex">
              <div class="px-0 mt-2 turn-address">
                <holding-pen :fen="game.board" side="b" />
              </div>
            </div>
        </div>
        <div class="pa-0 countdown-clock__wrap flex-grow-0 flex-sm-grow-1">
          <countdown-clock
            :expiresAt="game.turnExpiresAtDate"
            :disabled="!liveGame"
            :fill="game.turn === game.white ? 'white' : 'black'"
          />
        </div>
        <div class="mr-2 px-0 scoreboard-compressed">
          <div class="board-side__guest d-flex ma-0 pa-0 pr-3 justify-space-between flex-row-reverse" :class="{active: game.white === game.turn}">
            <div class="flex-grow-0 py-0 turn-role guest">GUEST</div>
            <div class="flex-grow-0 pa-0 turn-pointer guest">{{turnPointerGuest}}</div>
          </div>
          <div class="board-side__guest d-flex mx-0 ">
            <div class="pa-0 turn-address guest">{{whiteAcct}}</div>
          </div>
          <div class="board-side__guest d-flex">
            <div class="px-0 mt-2 turn-address">
              <holding-pen :fen="game.board" side="w" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader';
import Chessboard from '@/components/Chessboard';
import CountdownClock from '@/components/CountdownClock';
import JoinGameDialog from '@/components/JoinGameDialog';
import ConfirmDialog from '@/components/ConfirmDialog';
import ActionPendingDialog from '@/components/ActionPendingDialog';
import GameManagerMixin from '@/mixins/game-manager';
import HoldingPen from '@/components/HoldingPen';
import { NULL_ADDRESS } from '@/lib/ether';
import { describeGameState, GameState } from '@/lib/chess';
import ms from 'ms';
const pause = (delay) => (new Promise((resolve) => (setTimeout(resolve, delay))));
const capturedRole = {
  k: 'king',
  q: 'queen',
  n: 'knight',
  b: 'bishop',
  r: 'rook',
  p: 'pawn'
};

export default {
  name: 'Game',
  components: {
    PageHeader,
    Chessboard,
    JoinGameDialog,
    ConfirmDialog,
    ActionPendingDialog,
    CountdownClock,
    HoldingPen
  },
  mixins: [GameManagerMixin],
  computed: {
    turnPointer: function () {
      return '<<<<<<<<<<<<<<<<';
    },
    turnPointerHost: function () {
      return '>>>>>';
    },
    turnPointerGuest: function () {
      return '<<<<<';
    },
    game: function () {
      return this.games.get(this.$route.params.id);
    },
    liveGame: function () {
      return this.game?.gameState === GameState.Live;
    },
    turnTimeoutStr: function () {
      return this.game && ms(this.game.turnTimeout * 1000, { long: true });
    },
    showJoin: function () {
      return this.game && (this.game.gameState === GameState.New) &&
        (this.game.black !== this.account) &&
        (this.game.white === NULL_ADDRESS || this.game.white === this.account);
    },
    disabled: function () {
      return (this.game)
        ? (this.game.gameOver) ||
          ((this.game.black !== this.account) && (this.game.white !== this.account))
        : true;
    },
    enableSide: function () {
      if (this.game) {
        if (this.game.black === this.account) {
          return 'b';
        } else
        if (this.game.white === this.account) {
          return 'w';
        }
      }
      return null;
    },
    turnClasses: function () {
      const classes = [];
      if (this.game) {
        if (this.game.black === this.game.turn) {
          classes.push('turn-black');
        } else {
          classes.push('turn-white');
        }
      }
      return classes;
    },
    canClaimTrophy: function () {
      return this.game?.hasTrophy === false
        && this.game?.gameState === GameState.CheckMate
        && this.game?.winner === this.account;
    },
    canShowTrophy: function () {
      return this.game?.hasTrophy 
        && this.game?.winner === this.account
        && !!this.tokenListingAddress;
    },
    trophyAddress: function () {
      return `${this.tokenListingAddress}?a=${this.game.id}`;
    },
    describeGame: function () {
      return describeGameState(this.game, this.account);
    },
    endGameText: function () {
      const isPlayer = ((this.account === this.game.white) || (this.account === this.game.black));
      if ((this.game?.gameState === GameState.New) && (this.account === this.game?.black)) {
        return 'Cancel Game';
      } else
      if ((this.game.gameState === GameState.Live) && (isPlayer)) {
        if ((this.game.turnExpiresAtDate.valueOf() < Date.now()) && (this.account !== this.game.turn)) {
          return 'Timeout Game';
        } else {
          return 'Forfeit Game';
        }
      }

      return 'End Game';
    }
    // pendingMove: function(){
    //   return (this.gameBoard && (this.gameBoard != this?.game.board));
    // }
  },
  data: function () {
    return {
      pendingMove: null,
      pendingAction: {},
      showLastmoveTimer: null,
      hasTrophy: false,
      blackAcct: null,
      whiteAcct: null
    };
  },
  methods: {
    async onMove (d) {
      if (!d.history.length) {
        return;
      }
      const payload = {
        board: this.game.board,
        move: d.history.pop()
      };
      const timeout = setTimeout(() => {
        this.snackbarText = 'Validating move..';
        this.snackbar = true;
      }, 1000);
      try {
        this.pendingMove = await this.$store.dispatch('chain/validateMove', payload);
      } catch (err) {
        alert(err.message);
        this.undoMove();
      }
      clearTimeout(timeout);
      this.snackbar = false;
    },
    async submitMove () {
      try {
        this.pendingAction = {};
        this.$refs.actionPending.show('Sending your move to the blockchain, this may take a moment....');
        const r = await this.$store.dispatch('chain/makeMove', {
          game: this.game,
          pendingMove: this.pendingMove
        });
        this.pendingMove = null;
        this.pendingAction = {
          message: 'Your move has been recorded on the block chain.',
          transactionHash: r.transactionHash
        };
        this.$refs.actionPending.showSlot();
      } catch (err) {
        this.$refs.actionPending.show(err.message, 5000);
      }
    },
    undoMove () {
      this.pendingMove = null;
      this.$refs.chessboard.loadPosition();
    },
    async showLastMove () {
      if (!this.game.lastMoveFrom) {
        return;
      }
      if (this.showLastMoveTimer) {
        return;
      }
      const [lastMoveTo, color, capture] = this.game.lastMoveTo.split('/');
      const orig = this.game.lastMoveFrom;
      const dest = lastMoveTo.substr(-2);
      const role = capturedRole[capture];

      // Animation 1: Moving pieces around
      this.$refs.chessboard.board.move(dest, orig);
      if (role) {
        await pause(200);
        this.$refs.chessboard.board.setShapes([
          { orig: dest, piece: { role, scale: 1, color } }
        ]);
      }
      await pause(1000);
      this.$refs.chessboard.board.move(orig, dest);
      await pause(200);
      this.$refs.chessboard.board.setShapes([]);

      // Animation 2: Show an arrow
      // this.$refs.chessboard.board.setShapes([
      //   {
      //     orig, dest, brush: 'blue', modifiers: { lineWidth: 4 }
      //   },
      // ]);
      // await pause(5000);
      // this.$refs.chessboard.board.setShapes([]);
    }
  },
  async mounted () {
    if (!this.game) {
      return;
    }
    await this.$store.dispatch('chain/getGameById', this.game.id);
    const names = await this.lookupENSNames([ this.game.black, this.game.white ]);
    this.blackAcct = names.get(this.game.black);
    this.whiteAcct = names.get(this.game.white);
  },
  async updated() {
    // fixes a chessboard bug where clicks are mapped to the wrong
    // chessboard square.
    this.$refs.chessboard.board.redrawAll();
  }
};
</script>
<style lang="sass" scoped>
  @import '@/assets/css/variables.sass'

  .pending
    animation: blinker 1s ease-in infinite
    animation-delay: 2s
  
  @keyframes blinker
    50%
      opacity: 0
  

  .board-container
    display: block
    position: relative
    width: 20rem
    height: 20rem
    margin: auto

  .turn-pointer
    visibility: hidden

  .active .turn-pointer
    visibility: visible

  .turn-address
    font-family: RobotoMono
    font-size: 0.75rem
    max-width: 20rem
    min-width: 4rem
    line-height: 1.25rem
    letter-spacing: 0.2rem
    word-break: break-all

  .board-side__host,
  .board-side__guest
    max-width: 100%

  .board-side__host *
    color: black

  .scoreboard-full
    display: none

  .scoreboard-compressed
    display: block
    width: 8rem

  .lastmove-full__wrap
    display: none

  .lastmove-compressed__wrap
    display: flex

  .btn-showlastmove:disabled,
  .btn-showlastmove[disabled]
    opacity: 0.5

  .button-bar__group button
    word-spacing: 10000%

  @media only screen and (min-width: 550px)
    .button-bar__group button
      word-spacing: normal
      padding-top: 0.75rem
      padding-bottom: 0.75rem

    .board-container
      width: 28rem
      height: 28rem

    .scoreboard-compressed
      width: 10rem
     
  @media only screen and (min-width: 620px)
    .scoreboard-compressed
      width: 14rem

  @media only screen and (min-width: 768px)
    .board-container
      width: 32rem
      height: 32rem


  @media only screen and (min-width: 810px)
    .board-container
      width: 36rem
      height: 36rem

    .turn-address
      font-size: 1rem
      max-width: 17.5rem

  @media only screen and (min-width: 820px)
    .scoreboard-full
      display: flex

    .lastmove-full__wrap
      display: flex

    .scoreboard-compressed
      display: none

    .lastmove-compressed__wrap
      display: none

    .turn-role
      min-width: 5rem

    .game-page
      max-width: unset

    .button-bar
      width: 36rem

  @media only screen and (min-width: 820px) and (min-height: 1024px)
    .countdown-clock__wrap
      position: fixed
      width: 100%
      bottom: 0.25rem
      left: 0

</style>
<style>
/*
 * Most of these styles were required to override
 * effects on the chess board component when rendered
 * within a vuetify v-app container
 *
 */

.game-page .board-container .cg-board-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
}

.game-page .board-container .cg-board-wrap .black {
  background-color: rgba(0,0,0,0) !important;
  border-color: rgba(0,0,0,0) !important;
}

.game-page .board-container .cg-board-wrap .white {
  background-color: rgba(0,0,0,0) !important;
  border-color: rgba(0,0,0,0) !important;
}

.game-page .board-container .blue {
  background-color: rgba(0,0,0,0) !important;
}

.game-page .board-container .cg-board-wrap svg {
  position: absolute !important;
}

/*
.game-page .board-container .cg-board-wrap svg {
  opacity: 1;
}
.game-page .board-container .cg-board-wrap svg line {
  stroke-linecap: square;
}
*/
/* These classes are applied to elements showing the previous turn */
.game-page .board-container.turn-white .cg-board-wrap svg line {
  stroke: black;
}
.game-page .board-container.turn-white .cg-board-wrap svg marker path {
  fill: black;
}
.game-page .board-container.turn-black .cg-board-wrap svg line {
  stroke: white;
}
.game-page .board-container.turn-black .cg-board-wrap svg marker path {
  fill: white;
}

/* These apply to the current turn */
.game-page .board-container.turn-white .cg-board square.move-dest {
  border-color: white;
}
.game-page .board-container.turn-white .cg-board square.last-move {
  border-color: white;
}
.game-page .board-container.turn-white .cg-board square.selected {
  border-color: white;
}
.game-page .board-container.turn-black .cg-board square.move-dest {
  border-color: black;
}
.game-page .board-container.turn-black .cg-board square.last-move {
  border-color: black;
}
.game-page .board-container.turn-black .cg-board square.selected {
  border-color: black;
}
</style>
