<template>
  <form class="container d-flex flex-column pt-6" @submit.prevent="submit">
    <section class="row flex-column">
      <label for="create-game__amount">What are the table stakes in ether?</label>
      <p class="form-hint">The amount of Ether you would like to stake on this Game. Your opponent will need to match this stake to play.</p>
      <input id="create-game__amount" type="number" v-model="$v.amount.$model" />
      <p class="form-error" v-if="!$v.amount.validAmount">Field must be a number</p>
    </section>
    <section class="row flex-column">
      <label for="create-game__opponent">Would you like to reserve this game for another player?</label>
      <p class="form-hint">Enter the account number to reserve the game for a specific oppponent. If this is left blank anyone can join your game.</p>
      <input id="create-game__opponent" type="text" v-model="addrWhite" />
      <p class="form-error" v-if="!$v.addrWhite.validAddress">Not a valid address</p>
    </section>
    <section class="row flex-column">
      <label for="create-game__turnTimeout">Max time allowed for a turn</label>
      <p class="form-hint">Amount of time before a game can be <router-link :to="{ path: 'rules', hash: 'rules-cancel'}" >forfeit</router-link> due to delay of turn. Must be between {{minExpireStr}} and {{maxExpireStr}}.</p>
      <div class="row">
        <div class="col flex-grow-0">
          <input id="create-game__turnTimeout" type="text" v-model="turnTimeout"/>
        </div>
        <div class="col">
          <input type="radio" id="create-game__turnTimeout-hours" value="Hours" v-model="turnTimeoutUnits" />
          <label
            for="id-create-game__turnTimeout-hours"
            class="option-label"
            :class="{'option-label__active': (turnTimeoutUnits === 'Hours')}">Hours</label>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <input type="radio" id="create-game__turnTimeout-days" value="Days" v-model="turnTimeoutUnits" />
          <label
            for="id-create-game__turnTimeout-days"
            class="option-label"
            :class="{'option-label__active': (turnTimeoutUnits === 'Days')}">Days</label>
        </div>
      </div>
      <p class="form-error" v-if="!$v.turnTimeout.validTimeout">Must be a value between {{minExpireStr}} and {{maxExpireStr}}.</p>
    </section>
    <section class="row flex-column">
      <p class="row my-0 form-hint">
        <span class="col-8 py-0">Amount commited to game contract</span>
        <span class="col  py-0 ether">{{amount || '0'}} ETH</span>
      </p>
      <p class="row my-0 d-flex justify-space-around form-hint">
        <span v-if="this.gameFeePct" class="col-8 py-0">Game Fee ({{this.gameFeeStr}})</span>
        <span v-else class="col-8 py-0">Game Fee</span>
        <span class="col py-0 ether">{{estimatedGameFee}} ETH</span>
      </p>
    </section>
    <section class="row">
      <div>
        <input type="checkbox" id="create-game__terms" value="true" v-model="agreeToTerms" :disabled="$v.$invalid">
        <span class="disclaimer">&nbsp;&nbsp;I have reviewed the <router-link to="/rules">rules</router-link> and agree that by creating this game I am risking the loss of some or all of my Table Stakes.</span>
        </div>
    </section>
  </form>
</template>
<script>
import { NULL_ADDRESS, toBN, fromWei, keccak256, toWei, isAddress } from '@/lib/ether';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import ms from 'ms';
export default {
  name: 'CreateGameForm',
  mixins: [validationMixin],
  props: {
    hideSubmit: {
      type: Boolean,
      required: false,
      default: false
    },
    minExpire: {
      type: Number,
      required: false
    },
    maxExpire: {
      type: Number,
      required: false
    },
    gameFeePct: {
      type: Number,
      required: false
    }
  },
  computed: {
    minExpireStr: function () {
      return ms(this.minExpire * 1000, { long: true });
    },
    maxExpireStr: function () {
      return ms(this.maxExpire * 1000, { long: true });
    },
    gameFeeStr: function () {
      const adj = (isAddress(this.addrWhite) && (this.addrWhite !== NULL_ADDRESS))
        ? 2
        : 1;
      return `${this.gameFeePct / adj}%`;
    },
    estimatedGameFee: function () {
      if ((!this.gameFeePct) || (!this.amount)) {
        return 0;
      }
      const amt = toBN(toWei(this.amount.toString()));
      const feePct = toBN((100 * this.gameFeePct.toString()));
      const adj = (isAddress(this.addrWhite) && (this.addrWhite !== NULL_ADDRESS))
        ? '2'
        : '1';
      return fromWei(amt.mul(feePct).div(toBN('10000')).div(toBN(adj)));
    }
  },
  data: function () {
    return {
      agreeToTerms: false,
      amount: null,
      addrWhite: '',
      turnTimeout: 24,
      turnTimeoutUnits: 'Hours',
      showAdvanced: false
    };
  },
  validations: {
    amount: {
      required,
      validAmount (v) {
        return this.$v.$anyDirty
          ? (v && !isNaN(v))
          : true;
      }
    },
    addrWhite: {
      validAddress (v) {
        return (this.$v.$anyDirty && !!v)
          ? isAddress(v)
          : true;
      }
    },
    turnTimeout: {
      validTimeout (v) {
        if ((v === null) || (v === undefined) || (v === '')) {
          return true;
        }
        if (!`${v}`.match(/^\d+$/)) {
          return false;
        }
        const t = ms(`${v} ${this.turnTimeoutUnits}`) / 1000;
        return (t >= this.minExpire && t <= this.maxExpire);
      }
    }
  },
  watch: {
    agreeToTerms: function (v) {
      const isValid = (v === true) && (!this.$v.$invalid);
      this.$emit('valid', isValid);
    },
    turnTimeoutUnits: function () {
      const tt = this.turnTimeout;
      this.turnTimeout = null;
      setTimeout(() => { this.turnTimeout = tt; }, 0);
    },
    '$v.$invalid': function (v) {
      const isValid = (v !== true) && (this.agreeToTerms === true);
      this.$emit('valid', isValid);
    }
  },
  methods: {
    keccak256,
    onCreateGame () {
      let turnTimeout = null;
      if (this.turnTimeout) {
        turnTimeout = ms(`${this.turnTimeout} ${this.turnTimeoutUnits}`) / 1000;
      }
      this.$emit('submit', {
        amount: toWei(this.amount),
        addressWhite: this.addrWhite,
        turnTimeout: turnTimeout
      });
    },
    onCancel () {
      this.$emit('cancel');
    }
  }
};
</script>
<style scoped>
  label {
    font-family: RobotoMono;
    font-size: 1.125rem;
    color: white;
  }
  section {
    margin-bottom: 1rem;
  }

  input[type="text"],
  input[type="number"] {
    font-family: RobotoMono;
    padding: 0.75rem 0.75rem;
    max-width: 12rem;
  }

  #create-game__turnTimeout {
    width: 4rem;
  }

  .form-hint {
    font-family: RobotoMono;
    font-size: 0.75rem;
    color: black;
    line-height: 1rem;
  }

  .form-error {
    font-family: RobotoMono;
    font-size: 0.75rem;
    color: red;
    line-height: 1rem;
    margin-top: 0;
    margin-bottom: 0;
  }

  .option-label {
    font-size: 0.75rem;
  }
  .option-label__active {
    text-decoration: underline;
  }

  .disclaimer {
    font-family: RobotoMono;
    font-weight: 700;
    color: white;
    line-height: 1rem;
  }

</style>
