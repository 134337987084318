import { mapState, mapGetters } from 'vuex';
import { keccak256, fromWei } from '@/lib/ether';
import { GameState } from '../lib/chess';
export default {
  computed: {
    ...mapState({
      account: state => state.chain.account,
      publicMode: state => state.chain.publicMode,
      balance: state => state.chain.balance,
      connected: state => state.chain.connected,
      contract: state => state.chain.contract,
      games: state => state.chain.games,
      gasCreateGame: state => state.chain.gasCreateGame,
      minExpire: state => state.chain.minExpire,
      maxExpire: state => state.chain.maxExpire,
      gameFeePct: state => state.chain.gameFeePct,
      chainId: state => state.chain.chainId,
      network: state => state.chain.chain?.name,
      chain: state => state.chain.chain
    }),
    ...mapGetters('chain', [
      'ready',
      'openGames',
      'liveGames',
      'recentGames',
      'myCurrGames',
      'myPastGames',
      'memberStatus',
      'tokenListingAddress'
    ]),
    etherScanTxUrl: function () {
      if (this.chain?.explorer?.url) {
        return `${this.chain.explorer.url}/tx`;
      }
      return null;
    },
  },
  data: function () {
    return {
      pendingAction: {},
      snackbar: false,
      snackbarText: null
    };
  },
  methods: {
    fromWei,
    keccak256,
    async lookupENSNames(addresses) {
      return this.$store.dispatch('chain/addressToName', addresses);
    },
    async showCreateGame () {
      this.pendingAction = {};
      this.$refs.createGameDialog.dialog = true;
    },
    async onCreateGame (payload) {
      try {
        this.$refs.createGameDialog.dialog = false;
        this.$refs.actionPending.show('Sending request to the blockchain, this may take a moment....');
        const r = await this.$store.dispatch('chain/createGame', payload);
        this.pendingAction = {
          message: 'Your game has been created on the block chain.',
          gameId: r?.events?.GameCreated?.returnValues?.gameId,
          transactionHash: r.transactionHash
        };
        this.$refs.actionPending.showSlot();
      } catch (err) {
        this.$refs.actionPending.show(err.message, 5000);
      }
    },
    async endGame (game) {
      let warning = '';
      if (game.gameState === GameState.New) {
        if (game.turnExpiresAtDate.valueOf() > Date.now()) {
          warning = 'Game Fees will be forfeit if you cancel before ' +
            `${game.turnExpiresAtDate.toLocaleString()}.`;
        }
      } else
      if (game.gameState === GameState.Live) {
        if (game.turn === this.account) {
          warning = 'You will be in forfeit if you cancel now.  The Game Stake will go ' +
            'to your opponent.';
        } else
        if (game.turnExpiresAtDate.valueOf() > Date.now()) {
          warning = 'You will be in forfeit if you cancel now.  The Game Stake will go ' +
            'to your opponent.';
        }
      }
      if (await this.$refs.confirm.open(
        'End Game',
        `Are you sure you want to end this game? ${warning}`
      )) {
        try {
          this.$refs.actionPending.show('Sending request to the blockchain, this may take a moment....');
          const r = await this.$store.dispatch('chain/cancelGame', game.id);
          this.pendingAction = {
            message: 'Your request has been recorded on the block chain.',
            transactionHash: r.transactionHash
          };
          this.$refs.actionPending.showSlot();
        } catch (err) {
          this.$refs.actionPending.show(err.message, 5000);
        }
      }
    },
    async joinGame (game) {
      const stake = fromWei(game.amount);
      const hostName = (await this.lookupENSNames([game.black])).get(game.black);
      if (stake) {
        if (!(await this.$refs.joinGameDialog.open({
          host: game.black,
          hostName,
          amount: game.amount,
          gameFee: game.gameFee,
          turnTimeout: game.turnTimeout
        }))) {
          return;
        }
      }
      try {
        this.$refs.actionPending.show('Sending request to the blockchain, this may take a moment....');
        const r = await this.$store.dispatch('chain/joinGame', game.id);
        this.pendingAction = {
          message: 'Your joining the game has been recorded on the block chain.',
          gameId: r?.events?.GameJoined?.returnValues?.gameId,
          transactionHash: r.transactionHash
        };
        this.$refs.actionPending.showSlot();
      } catch (err) {
        this.$refs.actionPending.show(err.message, 5000);
      }
    },
    async claimTrophy (game) {
      try {
        this.$refs.actionPending.show('Sending request to the blockchain, this may take a moment....');
        const r = await this.$store.dispatch('chain/claimTrophy', game.id);
        this.pendingAction = {
          message: 'Your trophy has been creted and recorded on the block chain.',
          gameId: game.id,
          transactionHash: r.transactionHash
        };
        this.$refs.actionPending.showSlot();
      } catch (err) {
        this.$refs.actionPending.show(err.message, 5000);
      }      
    },
    async onCta ({ action, game }) {
      try {
        if (action === 'cancel') {
          this.endGame(game);
        } else
        if (action === 'join') {
          this.joinGame(game);
        } else
        if (action === 'claim') {
          this.claimTrophy(game);
        } else
        if ((action === 'view') || (action === 'play')) {
          this.$router.push({ name: 'Game', params: { id: game.id } });
        }
      } catch (err) {
        this.$refs.actionPending.show(err.message, 5000);
      }
    }
  }
};
