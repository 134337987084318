<template>
  <div class="d-flex flex-column justify-center background-highlight" style="height: 100%;">
    <v-snackbar top light v-model="snackbar">{{snackbarText}}</v-snackbar>
    <action-pending-dialog ref="actionPending" />
    <div class="d-flex justify-center">
      <div v-if="initializing">
       <img class="d-block mx-auto" src="@/assets/img/logo.svg" alt="pawn" width="181"/>
        <p class="text-center mt-2">Just a moment please, .... </p>
      </div>
      <div v-else-if="!available">
        <img class="d-block mx-auto" src="@/assets/img/logo.svg" alt="pawn" width="181"/>
        <p class="text-center mt-2">Playing in this club requires an Ethereum enabled browser.  Check out <a target="_" href="https://metamask.io" >Metamask</a>.</p>
      </div>
      <div v-else>
        <button
          class="btn-connect pt-4 pb-4"
          @click="connectToChain"
          text
        >
          <img class="d-block mx-auto" src="@/assets/img/logo.svg" alt="block chain chess logo" width="181"/>
          <p class="mt-8 mb-2" >You may now enter the club >></p>
      </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import ActionPendingDialog from '@/components/ActionPendingDialog';
export default {
  name: 'Connect',
  components: { ActionPendingDialog },
  computed: {
    ...mapState({
      chain: state => state.chain
    }),
    ...mapGetters('chain', ['available'])
  },
  props: {
  },
  data: function () {
    return {
      snackbar: false,
      snackbarText: null,
      initializing: false
    };
  },
  methods: {
    async connectToChain () {
      try {
        this.snackbarText = 'Connecting..';
        this.snackbar = true;
        await this.$store.dispatch('chain/connect');
        if (this.$route.query.r) {
          this.$router.replace(this.$route.query.r);
        }
      } catch (err) {
        this.$refs.actionPending.show(err.message, 3000);
      }
      this.snackbar = false;
    }
  },
  async mounted () {
    this.initializing = true;
    await this.$store.dispatch('chain/getContractMetadata');
    this.initializing = false;
  }
};

</script>
<style scoped>
  .background-highlight {
    background: radial-gradient(57.91% 57.91% at 48.54% 42.09%, #4C9CD6 0%, #4479CA 48.44%);
  }
</style>
