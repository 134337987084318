<template>
  <div class="d-flex flex-wrap">
    <div v-for="(piece, index) in capturedSide" :key="`captured-${index}-${piece}`">
      <svg class="piece-wrap">
        <use :xlink:href="require('@/assets/img/pieces.svg')+'#'+pieceId(piece)" :class="`piece ${pieceColor(piece)}`"></use>
      </svg>
    </div>
  </div>
</template>
<script>
import { parseFen } from '@/lib/chess';

export default {
  name: 'HoldingPen',
  props: {
    fen: String,
    side: String
  },
  computed: {
    capturedSide: function () {
      if (this.side === 'b') {
        return this.captured.filter(v => (['r', 'n', 'b', 'q', 'k', 'p'].includes(v)));
      } else
      if (this.side === 'w') {
        return this.captured.filter(v => (['R', 'N', 'B', 'Q', 'K', 'P'].includes(v)));
      }
      return null;
    }
  },
  watch: {
    fen: function (v) {
      if (v) {
        this.captured = parseFen(v).captured;
      } else {
        this.captured = [];
      }
    }
  },
  methods: {
    pieceId: function (piece) {
      piece = piece?.toLowerCase();
      if (piece === 'r') { return 'rook'; }
      if (piece === 'n') { return 'knight'; }
      if (piece === 'b') { return 'bishop'; }
      if (piece === 'q') { return 'queen'; }
      if (piece === 'k') { return 'king'; }
      if (piece === 'p') { return 'pawn'; }
      return null;
    },
    pieceColor: function (piece) {
      if (['r', 'n', 'b', 'q', 'k', 'p'].includes(piece)) {
        return 'black';
      } else
      if (['R', 'N', 'B', 'Q', 'K', 'P'].includes(piece)) {
        return 'white';
      }
      return null;
    }
  },
  data: function () {
    return {
      captured: []
    };
  },
  mounted () {
    if (this.fen) {
      this.captured = parseFen(this.fen).captured;
    }
  }
};
</script>
<style lang="sass" scoped>
  .piece-wrap
    width: 50px
    height: 50px

  .piece.black
    stroke: black
    stroke-width: 3

  .piece.white
    stroke: white
    stroke-width: 3

  @media only screen and (max-width: 768px)
    .piece-wrap
      transform: scale(.65,.65)
</style>
