import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/pages/home';
import Connect from '@/pages/connect';
import FAQ from '@/pages/faq';
import Games from '@/pages/games';
import Game from '@/pages/game';
import Rules from '@/pages/rules';
import E404 from '@/pages/E404';
import E409 from '@/pages/E409';
import store from '@/store';

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
      path: '/connect',
      name: 'Connect',
      component: Connect
    },
    {
      path: '/faq',
      name: 'FAQ',
      component: FAQ
    },
    {
      path: '/games/',
      name: 'Games',
      component: Games
    },
    {
      path: '/games/:id',
      name: 'Game',
      component: Game
    },
    {
      path: '/rules',
      name: 'Rules',
      component: Rules
    },
    {
      path: '/404',
      name: 'E404',
      component: E404
    },
    {
      path: '/409',
      name: 'E409',
      component: E409
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
});

const allowed = ['Connect', 'FAQ', 'Rules'];
router.beforeEach((to, from, next) => {
  console.log('router.beforeEach', { to, from,
    ready: store.getters['chain/ready'] ,
    currentChain: store.getters['chain/currentChain'],
    hostChain: store.getters['chain/hostChain']
  });
  if (!store.getters['chain/ready'] && !allowed.includes(to.name)) {
    return next({ name: 'Connect', query: { r: to.fullPath } });
  } else
  if (to.name !== 'E409' && store.getters['chain/ready'] && (store.getters['chain/currentChain'] !== store.getters['chain/hostChain'])) {
    return next({ name: 'E409' });
  }
  else
  if (to.name === 'Game') {
    const game = store.state.chain?.games?.get(to.params.id);
    if (!game) {
      return next({ name: 'E404' });
    }
  }

  next();
});

export default router;
