<template>
  <div class="games-page pa-4">
    <div v-if="!connected">
      <p>Please connect to your ethereum provider.</p>
    </div>
    <div v-else>
      <page-header :amount="null" />
      <div class="mx-auto games-page__body" >
        <action-pending-dialog ref="actionPending">
          <template v-slot:message>
            <p>{{pendingAction.message}}<br/>
            Transaction Hash:
            <a v-if="etherScanTxUrl" target="_blank" :href="`${etherScanTxUrl}/${pendingAction.transactionHash}`">
            {{pendingAction.transactionHash | hash}}</a>
            <span v-else>{{pendingAction.transactionHash | hash}}</span>
              </p>
            <v-row>
              <v-col>
                <v-btn
                  color="primary"
                  @click="$router.push({name: 'Game', params: {id: pendingAction.gameId}} )">
                  Go to Game
                </v-btn>
              </v-col>
              <v-col>
                <v-btn @click="$refs.actionPending.close()">Close</v-btn>
              </v-col>
            </v-row>
          </template>
        </action-pending-dialog>
        <confirm-dialog ref="confirm" />
        <div class="row">
          <!--div :class="{'col-12 col-sm-3': showFilters, 'col-sm-3': !showFilters}" class="select-wrap" -->

          <div class="col-sm-3 select-wrap" >
            <label for="search__select" class="d-flex">
              <span class="px-0 flex-grow-1">Show Games</span>
              <button class="px-0 btn-filters" @click="showFilters = !showFilters">
                <span v-if="showFilters">Hide additional filters</span>
                <span v-else>Show additional filters<span v-if="address">*</span></span>
              </button>
            </label>
            <v-select
              id="search__select"
              class="custom"
              v-model="searchType"
              :items="selectItems"
              item-text="title"
              item-value="search"
              outlined
              >
            </v-select>
          </div>
         <div v-if="showFilters" class="col-sm-4">
            <label for="search_input__address" class="d-block mr-2">Search by Address</label>
            <input id="search_input__address" type="text" v-model="address" />
          </div>
        </div>
        <div v-if="search.length" class="row pt-4">
          <v-col  v-for="game in search" :key="game.id">
            <span class="d-flex game-card__wrapper">
              <game-card
                v-bind="game"
                :viewer="account"
                @cta="onCta({ ...$event, game })"
              />
            </span>
          </v-col>
        </div>
        <div v-else class="row mt-4">
          <p class="col no-results">
            No games found
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageHeader from '@/components/PageHeader';
import GameCard from '@/components/GameCard';
import ActionPendingDialog from '@/components/ActionPendingDialog';
import ConfirmDialog from '@/components/ConfirmDialog';
import GameManagerMixin from '@/mixins/game-manager';

export default {
  name: 'Games',
  mixins: [GameManagerMixin],
  components: { PageHeader, GameCard, ConfirmDialog, ActionPendingDialog },
  computed: {
    search: function () {
      const search = this.searchType;
      const address = this.address;
      let games;
      if (search === 'owner') {
        games = this.myCurrGames;
      } else
      if (search === 'history') {
        games = this.myPastGames;
      } else
      if (search === 'open') {
        games = this.openGames;
      } else
      if (search === 'live') {
        games = this.liveGames;
      } else {
        games = [...this.games.values()];
      }
      if (address) {
        games = games && games.filter(g => ((g.white === address) || (g.black === address)));
      }
      return games;
    }
  },
  data: function () {
    return {
      address: '',
      selectItems: [
        { search: 'owner', title: 'Mine Current' },
        { search: 'history', title: 'Mine Past' },
        { search: 'open', title: 'Available to Join' },
        { search: 'live', title: 'Other Peope\'s' },
        { search: 'all', title: 'All' }
      ],
      searchType: null,
      showFilters: false
    };
  },
  watch: {
    searchType: function (v) {
      if (this.$route.query.search !== v) {
        const query = { search: v };
        if (this.address) {
          query.address = this.address;
        }
        this.$router.replace({ name: 'Games', query });
      }
    },
    address: function (v) {
      if (this.$route.query.address !== v) {
        const query = { search: this.searchType };
        if (v) {
          query.address = v;
        }
        this.$router.replace({ name: 'Games', query });
      }
    }
  },
  methods: {
    getSearchType: function (v) {
      const search = (v || '').toLowerCase();
      return (this.selectItems.find(i => i.search === search) || this.selectItems[3]).search;
    }
  },
  created: function () {
    this.searchType = this.getSearchType(this.$route.query.search);
  }
};
</script>
<style lang="sass" scoped>
  @import '@/assets/css/variables.sass' // Using this should get you the variables

  label
    padding-bottom: 0.5rem

  input[type=text]
    font-family: RobotoMono
    padding: 0.5rem 0.5rem
    width: 100%

  .select-wrap
      min-width: 20rem

  .no-results
    font-family: RobotoMono
    color: black

  .btn-filters
    font-family: RobotoMono
    font-size: 0.75rem
    color: black

  .col
   padding-top: 0
   padding-bottom: 0

  .row
    margin: 0

  @media only screen and (max-width: 900px)
    .select-wrap, .col-sm-4
      min-width: 100%

</style>
