<template>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      scrollable
    >
      <v-card  :color="$vuetify.theme.themes.light.background" >
        <v-card-title class="pa-0">
          <v-toolbar
            :color="$vuetify.theme.themes.light.background"
            flat
          >
            <v-toolbar-title>Creating a new game</v-toolbar-title>
            <v-spacer></v-spacer>
            <button :color="$vuetify.theme.themes.light.background"
              @click="onCancel">
              <v-icon style="color:white">mdi-close</v-icon>
            </button>
          </v-toolbar>
        </v-card-title>
        <v-card-text class="form-container ma-2">
          <create-game-form
            ref="gameForm"
            :hideSubmit=true
            :gameFeePct="gameFeePct"
            :minExpire="minExpire"
            :maxExpire="maxExpire"
            @submit="onSubmit"
            @valid="$data.isValid = $event"
          ></create-game-form>
        </v-card-text>
        <v-card-actions class="row ma-0 pa-0">
          <button class="col-3 py-6 btn-cancel" @click="onCancel">Cancel</button>
          <button
            class="flex-grow py-6 col-9 btn-submit"
            :disabled="!isValid"
            @click="$refs.gameForm.onCreateGame()" >
              Create Game</button>
        </v-card-actions>
      </v-card>
    </v-dialog>
</template>
<script>
import CreateGameForm from '@/components/CreateGameForm';
export default {
  name: 'CreateGameDialog',
  components: { CreateGameForm },
  props: {
    // baseGasPriceWei: [ String, Object ],
    // estGasRequired: Number
    gameFeePct: {
      type: Number,
      required: false
    },
    minExpire: {
      type: Number,
      required: false
    },
    maxExpire: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    dialog: false,
    isValid: false
  }),
  methods: {
    onCancel () {
      this.dialog = false;
    },
    onSubmit (data) {
      this.$emit('onCreateGame', data);
    }
  }
};
</script>
<style lang="sass" scoped>
  @import '@/assets/css/variables.sass'
  .form-container
    border: 3px solid $app-accent-dark
    width: auto

  .btn-submit
    background-color: $app-accent
  .btn-cancel
    background-color: $app-accent-dark

  .btn-submit:disabled,
  .btn-submit[disabled]
    color: $app-accent-dark
</style>
