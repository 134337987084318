/*
 *
 * This module is pretty much a wrapper around the Web3 module, mostly
 * to create a thin layer of abstraction should Web3 eventually be swapped
 * out for some alternative (like ethers).
 *
 * To find the documentation for these, pleaes refer to
 * https://web3js.readthedocs.io/
 *
 */
import Web3 from 'web3';

export function keccak256 (value) {
  return Web3.utils.keccak256(value);
}

export function toWei (value, unit) {
  return Web3.utils.toWei(value, unit);
}

export function fromWei (value, unit) {
  return Web3.utils.fromWei(value, unit);
}

export function isAddress (address) {
  return Web3.utils.isAddress(address);
}

export function toBN (value) {
  return Web3.utils.toBN(value);
}

export const truncAcct = value => (
  value ? value.substring(0, 12) + '...' : ''
);

export const truncHash = value => (
  value ? value.substring(0, 12) + '...' : ''
);

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';
