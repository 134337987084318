<template>
  <div class="home-page">
    <v-snackbar app top light v-model="snackbar">{{snackbarText}}</v-snackbar>
    <div class="home-page__header d-flex px-3 px-sm-7 py-4 py-sm-6 justify-space-between ">
      <div class="d-flex d-flex-grow-1">
        <router-link to="/" class="d-flex align-center">
         <img v-if="isMobile" src="@/assets/img/logo-inverted.svg" alt="logo"
            width="65"/>
          <img v-else src="@/assets/img/logo.svg" alt="logo" width="100"/>
        </router-link>
      </div>
      <div class="d-flex flex-column align-end">
        <div class="d-flex">
          <img src="@/assets/img/ether.svg" alt="ether-symbol" width="20"/>
          <span class="d-inline-block pl-2">Total ether</span>
          <span class="d-inline-block pl-2" style="font-family: Roboto !important; line-height: 1.25">
            {{ balance | fromWei | round}} ETH</span>
        </div>
         <div class="membership-status mt-n3">
          <span>{{network}}</span>
        </div>
         <div class="membership-status mt-n1">
          <span>{{memberStatus}}</span>
        </div>
         <div class="membership-status mt-n1">
          <span>{{accountName | account}}</span>
        </div>
      </div>
    </div>
    <div class="px-3 px-sm-7 mb-10 mt-16">
     <!-- We were unable to locate the contract on the current network -->
      <div v-if="!contract">
        Unable to locate a club on this chain, try another.
      </div>
     <!-- We found the contract on the current network -->
      <div v-else>
        <div v-if="publicMode">
          <div class="row mb-4 mb-sm-8 px-4 justify-space-between justify-sm-start" >
            <span>
              <span class="section-title">Recent games</span>
              <span class="d-sm-none section-title pl-4">{{recentGames.length}}</span>
            </span>
            <button class="ml-4" @click="showCreateGame">
              <span class="ps-sm-8 pr-2 btn-accent">+</span>
              <span>New game</span>
            </button>
          </div>
          <div class="game-card-list row pa-0 mx-0 ml-2 mt-4 " >
            <div
              class="mb-8 mr-4 mr-sm-8"
              v-for="game in recentGames" :key="game.id">
              <game-card
                v-bind="game"
                :viewer="account"
                @cta="({action}) => onCta({ action, game })"
              />
            </div>
          </div>
        </div>
        <div v-else-if="myCurrGames.length">
          <div class="row mb-4 mb-sm-8 px-4 justify-space-between justify-sm-start" >
            <span>
              <span class="section-title ml-2">Your games</span>
              <span class="d-sm-none section-title pl-4">{{myCurrGames.length}}</span>
            </span>
            <button @click="showCreateGame">
              <span class="ps-sm-8 px-4 btn-accent">+</span>
              <span>New game</span>
            </button>
          </div>
          <div class="game-card-list row pa-0 mx-0 ml-2 mt-4 " >
            <div
              class="mb-8 mr-4 mr-sm-8"
              v-for="game in myCurrGames" :key="game.id">
              <game-card
                v-bind="game"
                :viewer="account"
                @cta="({action}) => onCta({ action, game })"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <span class="section-title">You have no live games</span>
          <button class="btn-create-game d-block mt-6 py-8 py-sm-13" @click="showCreateGame">
            <span class="px-2 btn-accent">+</span>
            <span>Create a new game </span>
          </button>
        </div>
      </div>
      <div v-if="myPastGames.length">
          <div class="row mt-8 mb-4 mb-sm-8 px-4 justify-space-between justify-sm-start" >
            <span>
              <span class="section-title ml-2">Past games:</span>
              <span class="section-title pl-4">{{myPastGames.length}}</span>
            </span>
            <router-link v-if="myPastGames.length > 3" to="/games/" :query="{search:'history'}" class="align-self-center">
              <span class="pl-4">See all</span>
            </router-link>
          </div>
          <div class="game-card-list row pa-0 mx-0 ml-2 mt-4 " >
            <div
              class="mb-8 mr-4 mr-sm-8"
              v-for="game in myPastGames.slice(0,3)" :key="game.id">
              <game-card
                v-bind="game"
                :viewer="account"
                @cta="({action}) => onCta({ action, game })"
              />
            </div>
          </div>
      </div>
      <div v-if="openGames.length" class="ml-2 px-2">
        <p class="mt-12 section-title">Join an open game</p>
        <open-game-list
          class="mt-2"
          :games="openGames"
          :hostNameLookup="lookupENSNames"
          @cta="onCta">
        </open-game-list>
      </div>
      <action-pending-dialog ref="actionPending">
        <template v-slot:message>
          <p>{{pendingAction.message}}<br/>
          Transaction Hash:
            <a v-if="etherScanTxUrl" target="_blank" :href="`${etherScanTxUrl}/${pendingAction.transactionHash}`">
            {{pendingAction.transactionHash | hash}}</a>
            <span v-else>{{pendingAction.transactionHash | hash}}</span>
          </p>
          <v-row>
            <v-col>
              <button
                @click="$router.push({name: 'Game', params: {id: pendingAction.gameId}} )">Go to Game
              </button>
            </v-col>
            <v-col><button @click="$refs.actionPending.close()">Close</button></v-col>
          </v-row>
        </template>
      </action-pending-dialog>
      <confirm-dialog ref="confirm" />
      <create-game-dialog
        ref="createGameDialog"
        :gameFeePct="gameFeePct"
        :minExpire="minExpire"
        :maxExpire="maxExpire"
        @onCreateGame="onCreateGame"
      ></create-game-dialog>
      <join-game-dialog
        ref="joinGameDialog"
        style="z-index: 100"
      ></join-game-dialog>
    </div>
    <div class="footer py-4">
      <div class="row ma-0 justify-center justify-sm-space-between">
        <div class="mb-2 mb-sm-0">
          <router-link class="mx-3" to="/rules">Read the Rules</router-link>
          <router-link class="mx-3" to="/faq">View the FAQ</router-link>
        </div>
        <div>
         <a class="mx-3" href="mailto: president@blockchainchess.club">Contact: president@blockchainchess.club</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OpenGameList from '@/components/OpenGameList';
import GameCard from '@/components/GameCard';
import CreateGameDialog from '@/components/CreateGameDialog';
import JoinGameDialog from '@/components/JoinGameDialog';
import ActionPendingDialog from '@/components/ActionPendingDialog';
import ConfirmDialog from '@/components/ConfirmDialog';
import GameManagerMixin from '@/mixins/game-manager';
export default {
  name: 'Home',
  components: {
    OpenGameList,
    GameCard,
    CreateGameDialog,
    JoinGameDialog,
    ConfirmDialog,
    ActionPendingDialog
  },
  mixins: [GameManagerMixin],
  computed: {
    isMobile: function () {
      return (this.$vuetify.breakpoint.name === 'xs');
    }
  },
  data: function () {
    return {
      accountName: null,
      hostNames: new Map()
    };
  },
  props: {
  },
  methods: {
    async showCreateGame () {
      this.pendingAction = {};
      this.$refs.createGameDialog.dialog = true;
    }
  },
  async mounted () {
    if (this.account) {
      this.accountName = (await this.lookupENSNames([ this.account ])).get(this.account);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/css/variables.sass'
.btn-create-game
  border: 0.375rem solid $app-accent
  width: 100%

.btn-accent
    font-weight: bold
    color: $app-accent !important

.section-title
  font-size: 1.625rem

.membership-status
  font-family: Roboto
  font-size: 0.75rem

.footer
  width: 100%
  font-family: RobotoMono
  font-size: 0.825rem
  background-color: $app-background
  z-index: 99
  position: fixed
  left: 0
  bottom: 0

.footer a:link
  text-decoration: none

@media only screen and (max-width: 484px)
  .home-page__header
    background-color: $app-accent

  .game-card-list
    display: flex
    flex-wrap: nowrap
    overflow-x: auto
    -ms-overflow-style: none  // IE 10+
    overflow: -moz-scrollbars-none  // Firefox

  .game-card-list::-webkit-scrollbar
    display: none // Safari and Chrome

</style>
