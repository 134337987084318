<template>
  <v-container fluid class="pt-0">
   <page-header :amount="null" />
    <h1>FAQ</h1>
    <h2>Why BlockChain Chess?</h2>
    <p>It seemed like something worth doing?</p>
    <span v-if="publicMode">
    <h2>How come I can't create or join a game?</h2>
    <p>Well that's because you are accessing the Club on a <i>normal</i> browser.  That's fine if you want to
    take a look around and see what this is all about.  But to play, you need to have a browser that is connected
    to an <a target="_blank" href="https://ethereum.org/en/wallets/">Ethereum Wallet</a>.  You can add that to your
    desktop browser using a plugin like <a target="_" href="https://metamask.io" >Metamask</a>.
    Or if you are on a phone you can install a Wallet app with a built in browser like <a target="_" href="https://metamask.io" >Metamask - Blockchain Wallet</a> or
    <a target="_blank" href="https://www.coinbase.com/wallet">Coinbase Wallet</a>.
    </p>
    </span>
    <h2>Is this one of those NFTs I've been hearing about?</h2>
    <p>No, but the winner of a game can claim an NFT as a Trophy to celebrate their victory.</p>
    <h2>How does this work?  Is it really <em>ALL</em> on the blockchain?</h2>
    <p>Mostly, but not all.  The moves, games, and transfers of stakes are all done "on-chain".  The validation
      of moves is handled by an off-chain <a href="https://ethereum.org/en/developers/docs/oracles/">Oracle</a>.  When you make
      a move, the current board along with your move is sent to a web-service that knows the rules of Chess and knows when you've
      won, lost, drawn, or should keep playing.  It can also tell if you're cheating!  Once your move is validated, it is signed
      using a secret key tied to an address that is stored as the validator address within the on-chain contract.  Your move is then
      submitted to the contract which checks the validation, makes sure the board that was used for the validation matches the board
      as it knew it before the move, and then records the move.</p>
    <span v-if="contractWebAddress">
    <h2>Where can I get a look at this contract?</h2>
    <p>It's online!  You can find it <a target="_blank" :href="contractWebAddress">here</a>.</p>
    </span>
    <h2>Do I have to pony up real Ether to play a game?</h2>
    <p>No! You can kick the tires on one of the following test networks</p>
    <ul>
      <li v-for="item in publicTestChains" :key="item.networkId">
        <a :href="item.club">{{ item.name }}</a>
      </li>
    </ul>
  </v-container>
</template>
<script>
import PageHeader from '@/components/PageHeader';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'FAQ',
  components: { PageHeader },
  computed: {
    ...mapState({
      publicMode: state => state.chain.publicMode
    }),
    ...mapGetters('chain', [
      'contractWebAddress',
      'publicTestChains'
    ])
  },
  data: function () {
    return {
    };
  }
};
</script>
<style scoped>
  .table {
    border-collapse: collapse;
    border: 2px solid white;
  }
  .table .cell {
    border: 2px solid white;
    font-family: RobotoMono !important;
    font-size: 0.75rem;
    color: white;
  }

  * {
    font-family: RobotoMono;
    color: black;
  }
  h1  {
    font-family: minecraft;
    color: white;
  }
  h2, h3, h4, th {
    color: white;
  }
  @media only screen and (min-width: 768px) {
    .table .cell {
      font-size: 1rem;
    }
  }

</style>
