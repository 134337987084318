<template>
  <v-app>
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'App',
  computed: {
    ...mapState({
      chain: state => state.chain,
      eventsSupport: state => state.chain.eventsSupport
    }),
    chainName: function () {
      return this.chain?.chain?.name || this.chain?.chainId;
    }
  },
  watch: {
    eventsSupport: function (v) {
      if ((v === false) && (this.timer === null)) {
        this.initRefreshTimer();
      } else
      if ((v === true) && (this.timer !== null)) {
        clearInterval(this.timer);
      }
    }
  },
  data: () => ({
    timer: null,
    snackbar: false,
    snackbarText: ''
  }),
  methods: {
    async connectToChain () {
      try {
        this.snackbarText = 'Connecting..';
        this.snackbar = true;
        await this.$store.dispatch('chain/connect');
      } catch (err) {
        alert(err.message);
      }
      this.snackbar = false;
    },
    initRefreshTimer () {
      this.timer = setInterval(async () => {
        try {
          await this.$store.dispatch('chain/refreshState');
        } catch (err) {
          console.error(err);
        }
      }, 15000);
    }
  }

};
</script>
