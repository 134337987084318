
export function formatTime ({ days, hours, minutes, seconds, direction }) {
  let fmt = `${hours < 10 ? '0' + hours : hours}:` +
    `${minutes < 10 ? '0' + minutes : minutes}:` +
    `${seconds < 10 ? '0' + seconds : seconds}`;
  if (days) {
    fmt = `${days < 10 ? '0' + days : days}:` + fmt;
  }

  return direction < 0 ? '+' + fmt : fmt;
}

export function msToTime (duration) {
  const direction = (duration < 0) ? -1 : 1;
  duration *= direction;
  const milliseconds = Math.floor((duration % 1000) / 100);
  const seconds = Math.floor((duration / 1000) % 60);
  const minutes = Math.floor((duration / (1000 * 60)) % 60);
  // hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
  // days = Math.floor((duration / (1000 * 60 * 60 * 24)) );
  // Use this if you want to roll the days into the hours
  const hours = Math.floor(duration / (1000 * 60 * 60)); // -
  // Math.floor((duration / (1000 * 60 * 60)) % 24);
  return {
    direction,
    milliseconds,
    seconds,
    minutes,
    hours
    // days
  };
}
