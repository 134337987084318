<template>
    <v-dialog
      v-model="dialog"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-row class="pa-4 ma-0">
          <v-col cols="3" align-self="center" align="center">
            <img src="@/assets/img/crown-small.svg" class="logo-crown" width="96" height="90"/>
          </v-col>
          <v-col v-if="slot" class="ml-4" align-self="center">
            <slot name="message"></slot>
          </v-col>
          <v-col v-else class="ml-4" align-self="center">
            <p v-if="message">{{message}}</p>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
</template>
<script>
export default {
  name: 'ActionPendingDialog',
  data: () => ({
    dialog: false,
    slot: false,
    message: null,
    timeout: null
  }),
  methods: {
    open ({ message, closeTimer, slot }) {
      this.clearCloseTimer();
      this.slot = slot;
      this.message = message;
      this.dialog = true;
      if (closeTimer) { this.close(closeTimer); }
    },
    show (message, closeTimer, slot) {
      this.open({ message, closeTimer, slot });
    },
    showSlot (closeTimer) {
      this.open({ slot: true, closeTimer });
    },
    clearCloseTimer () {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = null;
    },
    close (timeout = 0) {
      this.clearCloseTimer();
      this.timeout = setTimeout(() => {
        this.dialog = false;
      }, timeout);
    }
  }
};
</script>
<style scoped>
.logo-crown {
  width: 3rem;
  height: 2.813rem;
}

@media only screen and (min-width: 768px) {
  .logo-crown {
    width:  6rem;
    height: 5.625rem;
  }
}
</style>
