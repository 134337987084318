import Chess from 'chess.js';
import { truncAcct, NULL_ADDRESS } from './ether.js';

const PiecePlacement = 0;
const ActiveColor = 1;
const Castling = 2;
const EnPassant = 3;
const HalfMoveClock = 4;
const FullMove = 5;

export class GameState {
  static get New () { return 0; }
  static get Live () { return 1; }
  static get CheckMate () { return 2; }
  static get StaleMate () { return 3; }
  static get Draw () { return 4; }
  static get Canceled () { return 5; }
  static get Forfeit () { return 6; }
  static get Timeout () { return 7; }
  static get SysCanceled () { return 8; }
}

export class GameTurn {
  static get None () { return 0; }
  static get Black () { return 1; }
  static get White () { return 2; }
}

export class DrawType {
  static get None () { return 0; }
  static get Unknown () { return -1; }
  static get InsufficientMaterial () { return 1; }
  static get FiftyMoves () { return 2; }
}

export function parseFen (fen) {
  const g = new Chess();
  g.load(fen);

  const fields = fen.split(' ');
  const ranks = fields[PiecePlacement].split('/');

  const captured = {
    q: 1,
    r: 2,
    b: 2,
    n: 2,
    k: 1,
    p: 8,
    Q: 1,
    R: 2,
    B: 2,
    N: 2,
    K: 1,
    P: 8
  };

  const board = fields[PiecePlacement];
  for (let i = 0; i < board.length; i++) {
    const piece = board.charAt(i);
    if (captured[piece]) {
      captured[piece] -= 1;
    }
  }
  const capturedList = [];
  for (const pieceType in captured) {
    for (let i = 0; i < captured[pieceType]; i++) {
      capturedList.push(pieceType);
    }
  }

  let gameState = GameState.Live;
  let drawType = DrawType.None;

  if (g.in_checkmate()) {
    gameState = GameState.CheckMate;
  } else
  if (g.in_stalemate()) {
    gameState = GameState.StaleMate;
  } else
  if (g.in_draw()) {
    gameState = GameState.Draw;
    if (g.insufficient_material()) {
      drawType = DrawType.InsufficientMaterial;
    } else
    if (Number(fields[HalfMoveClock]) > 100) {
      drawType = DrawType.FiftyMoves;
    } else {
      drawType = DrawType.Unknown;
    }
  }

  return {
    fen,
    ranks,
    captured: capturedList,
    turn: fields[ActiveColor],
    castling: fields[Castling],
    enPassant: fields[EnPassant],
    halfMoveClock: Number(fields[HalfMoveClock]),
    fullMove: Number(fields[FullMove]),
    inCheck: g.in_check(),
    gameState,
    drawType
  };
}

export function describeGameState (game, viewer) {
  const parsedFen = parseFen(game.board);
  const viewerIsPlayer = ((viewer === game.black) || (viewer === game.white));
  let state = ''; let detail = '';

  do {
    // Handle open/pending games
    if (game.gameState === GameState.New) {
      if (viewer === game.white) {
        state = `Invited by ${truncAcct(game.black)}`;
        break;
      } else
      if (game.white !== NULL_ADDRESS) {
        if (viewerIsPlayer) {
          state = `Waiting for ${truncAcct(game.white)}`;
        } else {
          state = 'Private Game';
        }
        break;
      } else {
        state = 'Waiting for opponent';
        break;
      }
    } else
    // Handle live/active games
    if (game.gameState === GameState.Live) {
      if (Date.now() > game.turnExpiresAtDate.valueOf()) {
        if (game.viewer === game.turn) {
          state = 'You have gone overtime!';
        } else
        if (game.myGame) {
          state = 'Opponent has gone overtime!';
        } else
        if (game.turn === game.black) {
          state = 'Black has gone overtime';
        } else
        if (game.turn === game.white) {
          state = 'White has gone overtime';
        }
      } else {
        if (game.viewer === game.turn) {
          state = 'It\'s your turn';
        } else
        if (game.myGame) {
          state = 'It\'s your opponent\'s turn';
        } else
        if (game.turn === game.black) {
          state = 'It\'s Black\'s turn';
        } else
        if (game.turn === game.white) {
          state = 'It\'s White\'s turn';
        }
      }
      break;
    }
    // Handle Game Over scnenarios
    if (game.gameState === GameState.CheckMate) {
      if (viewer === game.winner) {
        state = 'You won!';
      } else
      if (viewerIsPlayer) {
        state = 'You Lost!';
      } else {
        if (game.winner === game.black) {
          state = 'Winner: Black!';
        } else {
          state = 'Winner: White!';
        }
      }
      detail = 'CheckMate';
      break;
    } else
    if (game.gameState === GameState.StaleMate) {
      detail = 'Stalemate';
    } else
    if (game.gameState === GameState.Draw) {
      if (parsedFen.drawType === DrawType.InsufficientMaterial) {
        detail = 'Insufficient Material';
      }
      if (parsedFen.drawType === DrawType.FiftyMoves) {
        detail = '50 Move Rule';
      }
    } else
    if (game.gameState === GameState.Forfeit) {
      if (viewerIsPlayer) {
        if (game.canceledBy === viewer) {
          detail = 'You quit';
        } else {
          detail = 'Your opponent quit';
        }
      } else {
        detail = (game.canceledBy === game.black)
          ? 'Black Quit'
          : 'White Quit';
      }
    } else
    if (game.gameState === GameState.Timeout) {
      if (viewerIsPlayer) {
        if (game.turn === viewer) {
          detail = 'You Timed out';
        } else {
          detail = 'Your Opponent timed out';
        }
      } else {
        detail = (game.turn === game.black)
          ? 'Black Timed out'
          : 'White Timed out';
      }
    } else
    if (game.gameState === GameState.Canceled) {
      detail = (game.canceledBy === viewerIsPlayer)
        ? 'You canceled'
        : 'Host canceled';
    } else
    if (game.gameState === GameState.SysCanceled) {
      detail = 'System Cancel';
    }
    state = 'Game Over';
    break;

  // eslint-disable-next-line no-constant-condition
  } while (false);

  return { state, detail };
}
