<template>
  <div class="d-flex flex-column countdown-container" :class="{ disabled }">
    <div class="row mx-0 mt-0 justify-space-around countdown--full">
      <div class="flex-grow-1 d-flex flex-column">
        <div class="row ma-0"><span class="time-block__header hour pl-1" :class="{overtime}">{{pad(hoursRemaining)}}</span></div>
        <div v-for="col in 6" :key="`index-hour-col${col}`" class="row ma-0 ">
          <div v-for="index in 4" :key="`index-hour-col-${col}-${index}`" class="col time-block__wrapper hour">
            <span class="d-block time-block hour" :class="{active: (col + ((index-1) * 6)) <= hoursRemaining, overtime }" />
          </div>
        </div>
      </div>
      <div>
        <div class="row ma-0"><span class="time-block__header minute pl-1" :class="{overtime}">:{{pad(minutesRemaining)}}</span></div>
        <div v-for="col in 10" :key="`index-minute-col${col}`" class="row ma-0">
          <div v-for="index in 6" :key="`index-minute-col-${col}-${index}`" class="time-block__wrapper minute">
            <span class="d-block time-block minute" :class="{active: (col + ((index-1) * 10))<= minutesRemaining, overtime }" />
          </div>
        </div>
      </div>
      <div>
        <div class="row ma-0"><span class="time-block__header second pl-1" :class="{overtime}">:{{pad(secondsRemaining)}}</span></div>
        <div v-for="col in 10" :key="`index-second-col${col}`" class="row ma-0">
          <div v-for="index in 6" :key="`index-second-col-${col}-${index}`" class="mx-0 time-block__wrapper second">
            <span class="d-block time-block second" :class="{active: (col + ((index-1) * 10))<= secondsRemaining, overtime }" />
          </div>
        </div>
      </div>
    </div>
    <!-- mobile version -->
    <div class="row mx-auto flex-column countdown--compressed" >
      <div class="mx-auto"><span class="countdown--compressed__header" :class="fillOverride">{{timeRemaining}}</span></div>
      <div class="d-flex">
        <div>
          <div v-for="index in 24" :key="`index-hour-${index}`" class="time-block__wrapper hour">
            <span class="d-block time-block hour" :class="{active: (24 - index) <= hoursRemaining,  ...fillOverride }" />
          </div>
        </div>
        <div>
          <div v-for="index in 60" :key="`index-minute-${index}`" class="time-block__wrapper minute">
            <span class="d-block time-block minute" :class="{active: (60 - index) <= minutesRemaining, ...fillOverride }" />
          </div>
        </div>
        <div>
          <div v-for="index in 60" :key="`index-second-${index}`" class="time-block__wrapper second">
            <span class="d-block time-block second" :class="{active: (60 - index) <= secondsRemaining,  ...fillOverride}" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { msToTime, formatTime } from '@/lib/time';
export default {
  name: 'CountdownClock',
  props: {
    expiresAt: Date,
    disabled: Boolean,
    fill: String
  },
  data () {
    return {
      now: Date.now(),
      timer: null
    };
  },
  computed: {
    overtime: function () {
      return (this.disabled === false) && (this.now > this.expiresAt.valueOf());
    },
    fillOverride: function () {
      const ret = {};
      if (this.overtime) {
        ret['fill-red'] = true;
      } else
      if (this.fill) {
        ret[`fill-${this.fill}`] = true;
      }
      return ret;
    },
    timeRemaining: function () {
      return formatTime(msToTime(this.expiresAt.valueOf() - this.now));
    },
    hoursRemaining: function () {
      return (this.overtime ? '+' : '') +
        msToTime(this.expiresAt.valueOf() - this.now).hours;
    },
    minutesRemaining: function () {
      return msToTime(this.expiresAt.valueOf() - this.now).minutes;
    },
    secondsRemaining: function () {
      return msToTime(this.expiresAt.valueOf() - this.now).seconds;
    }
  },
  methods: {
    pad (v) {
      return v < 10 ? `0${v}` : `${v}`;
    }
  },
  beforeCreate () {
    this.timer = setInterval(() => {
      this.now = Date.now();
    }, 1000);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  }
};
</script>
<style lang="sass" scoped>
  @import '@/assets/css/variables.sass'
  .row
    margin-top: 0
    margin-bottom: 0

  .countdown-container
    width: 100%

  .time-block__header
    color: $app-accent
    font-weight: 700

  .time-block__header.overtime
    color: rgba(255,0,0,0.8)

  .time-block__wrapper
    padding: 2px 4px

  .time-block
    background-color: $app-accent
    opacity: 0.2

  .time-block.active
    opacity: 1

  .time-block.active.overtime
    background-color: rgba(255,0,0,0.8)
    opacity: 0.75

  .time-block.hour
    width: 100%
    height: 19.5px

  .time-block.minute
    width: 40px
    height: 10px

  .time-block.second
    width: 4px
    height: 10px

  .disabled .time-block.active
    opacity: 0.2

  .disabled .time-block__header
    visibility: hidden

  .countdown--full
    display: flex

  .countdown--compressed
    display: none

  @media only screen and (max-width: 820px)
    .countdown--full
      display: none

    .countdown--compressed
      display: flex

    .time-block__wrapper
      padding: 0.5px 4px

    .time-block.hour
      width: 64.45px
      height: 9px

    .time-block.minute
      width: 44.32px
      height: 3px

    .time-block.second
      width: 24.45px
      height: 3px

    .countdown--compressed__header.fill-black
      color: black

    .countdown--compressed__header.fill-red
      color: rgba(255,0,0,0.75)

    .time-block.active.fill-red
      background-color: rgba(255,0,0,0.75)

    .time-block.active.fill-black
      background-color: black

    .time-block.active.fill-white
      background-color: white

    .disabled .time-block.active
      background-color: $app-accent
      opacity: 0.2

    .disabled .countdown--compressed__header
      visibility: hidden

  @media only screen and (max-width: 428px)
    .time-block__wrapper
      padding: 0.5px 4px

    .time-block.hour
      width: 16.45px
      height: 9px

    .time-block.minute
      width: 18.32px
      height: 3px

    .time-block.second
      width: 16.45px
      height: 3px
</style>
