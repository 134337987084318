
const infuraProjectId = process.env.VUE_APP_INFURA_PROJECT_ID;
const defaultDomain = process.env.VUE_APP_DEFAULT_DOMAIN;
export default {
  91919: {
    name: 'Local Club',
    chainId: 91919,
    network: 'localhost',
    networkId: 91919,
    rpc: {
      http: 'http://127.0.0.1:8545'
    },
    explorer: null,
    club: 'http://127.0.0.1:8080',
  },
  1: {
    name: 'Ethereum Mainnet',
    chainId: 1,
    network: 'mainnet',
    networkId: 1,
    rpc: infuraProjectId && {
      http: `https://mainnet.infura.io/v3/${infuraProjectId}`,
      ws: `wss://mainnet.infura.io/ws/v3/${infuraProjectId}`
    },
    explorer: {
      name: 'etherscan',
      url: 'https://etherscan.io'
    },
    club: `https://www.${defaultDomain}`,
    ens: {
      reverseRecordsAddress: '0x3671aE578E63FdF66ad4F3E12CC0c0d71Ac7510C'
    }
  },
  5: {
    name: 'Goerli Testnet',
    chainId: 5,
    network: 'goerli',
    networkId: 5,
    rpc: infuraProjectId && {
      http: `https://goerli.infura.io/v3/${infuraProjectId}`,
      ws: `wss://goerli.infura.io/ws/v3/${infuraProjectId}`
    },
    explorer: {
      name: 'etherscan',
      url: 'https://goerli.etherscan.io'
    },
    club: `https://goerli.${defaultDomain}`,
    publicTestChain: true,
    ens: {
      reverseRecordsAddress: '0x333Fc8f550043f239a2CF79aEd5e9cF4A20Eb41e'
    }
  },
  11155111: {
    name: 'Sepolia Testnet',
    chainId: 11155111,
    network: 'sepolia',
    networkId: 11155111,
    rpc: infuraProjectId && {
      http: `https://sepolia.infura.io/v3/${infuraProjectId}`,
      ws: `wss://sepolia.infura.io/ws/v3/${infuraProjectId}`
    },
    explorer: {
      name: 'etherscan',
      url: 'https://sepolia.etherscan.io'
    },
    club: `https://sepolia.${defaultDomain}`,
    publicTestChain: true
  }
};
