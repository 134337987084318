import Vue from 'vue';
import { fromWei, truncAcct, truncHash } from '@/lib/ether';

Vue.filter('account', function (value) {
  return value?.substr(0,2) === '0x'
    ? truncAcct(value)
    : value || '';
});

Vue.filter('hash', function (value) {
  return truncHash(value);
});

Vue.filter('round', function (value) {
  return Math.round(value * 10000) / 10000;
});

Vue.filter('fromWei', function (value) {
  if ((value === undefined) || (value === null)) {
    return '';
  }
  return fromWei(`${value}`);
});
