<template>
  <div
    class="open-game-list"
    elevation="0" >
    <v-row class="align-center justify-start ma-0">
      <v-col class="py-0 px-1 px-sm-3 flex-grow-0" v-for="game in games" :key="game.id">
        <button class="open-game d-block my-1 my-sm-2 mx-auto d-flex align-center justify-center"
           @click="onCta(game)">
          <section class="game-content">
            <img class="eth-logo d-block mx-auto" src="@/assets/img/ether-outline.svg" alt="ethereium logo"/>
            <p class="text-6 text-center mt-3 mb-0" style="font-family: Roboto !important">
              {{amount(game)}} ETH</p>
          </section>
        </button>
        <p class="host">Host: {{hostNames.get(game.black) || game.black}}</p>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { fromWei } from '@/lib/ether';
export default {
  name: 'OpenGameList',
  props: {
    games: Array,
    hostNameLookup: Function
  },
  data: function () {
    return {
      hostNames: new Map()
    };
  },
  methods: {
    amount (game) {
      return fromWei(game.amount);
    },
    onCta (game) {
      this.$emit('cta', { action: 'join', game });
    }
  },
  async mounted () {
    console.log('OpenGameList mounted', {
      hostNamesLookup: this.hostNameLookup
    });
    if (this.hostNameLookup) {
      this.hostNames = await this.hostNameLookup(this.games.map(game => game.black));
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/css/variables.sass'

button
  background-color: $app-accent-dark

.eth-logo
  width: 1.375rem

.open-game
  width: 7rem
  height: 7rem

.host
  font-family: RobotoMono
  font-size: 0.6rem
  padding-left: 0.1rem
  padding-right: 0.1rem
  color: black
  word-break: break-all

@media only screen and (min-width: 768px)
  .open-game
    width: 12rem
    height: 12rem

  .host
    font-size: 0.8rem
</style>
