<template>
  <div class="gamecard" :class="{'my-turn': this.myTurn, 'viewer-is-invited': this.viewerIsInvited}">
    <v-container class="pa-0">
      <v-row no-gutters class="pt-2 pb-2 header-wrap">
        <v-col class="d-flex">
          <div class="d-flex mx-auto">
            <img src="@/assets/img/ether-outline-white.svg" alt="ethereum symbol" width="14"/>
            <span class="d-inline-block pa-2" style="font-family: Roboto !important; line-height: 1.25">
              {{amountETH}} ETH</span>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="board-wrap pb-4">
        <v-col>
          <div class="board-container" @click="$emit('cta', { action: 'view' })">
            <chessboard :fen="board" cursor="pointer" disabled />
          </div>
        </v-col>
      </v-row>
      <v-row v-if="viewerIsInvited"
         no-gutters class="flex-column mt-3 join-game__wrap">
         <v-col  no-gutters class="d-flex justify-center">
          <span class="d-inline-block mx-auto">{{describeGame.state}}</span>
          </v-col>
        <v-col no-gutters class="d-flex justify-center py-4 ">
          <button style="text-decoration: underline" @click="$emit('cta', { action: 'join' })">Join Game</button>
        </v-col>
      </v-row>
      <v-row v-else no-gutters class="flex-column mt-3">
        <v-col  no-gutters class="d-flex justify-center">
          <span class="d-inline-block mx-auto">{{describeGame.state}}</span>
        </v-col>
        <v-col v-if="live" no-gutters class="d-flex justify-center mt-1 mb-7 countdown" >
          <span :class="{overtime}">{{countdown}}</span>
        </v-col>
        <v-col v-else no-gutters class="d-flex justify-center mt-1 mb-7 countdown" >
            <span>{{describeGame.detail}}</span>
          </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
/* eslint no-constant-condition: "off" */
import Chessboard from '@/components/Chessboard';
import { fromWei, NULL_ADDRESS } from '@/lib/ether';
import { msToTime, formatTime } from '@/lib/time';
import { parseFen, describeGameState, GameState } from '@/lib/chess';

const initialBoard = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';
// const WhiteColorType = '1';
// const BlackColorType = '2';
export default {
  name: 'GameCard',
  components: { Chessboard },
  props: {
    amount: String,
    black: String,
    board: String,
    canceledBy: String,
    updatedAtDate: Date,
    turnExpiresAtDate: Date,
    gameOver: Boolean,
    gameState: Number,
    id: String,
    turn: String,
    white: String,
    winner: String,
    viewer: String
  },
  computed: {
    live: function () {
      return (this.gameState === GameState.Live);
    },
    myTurn: function () {
      return (this.gameState === GameState.Live) && (this.viewer === this.turn);
    },
    myGame: function () {
      return ((this.viewer === this.white) || (this.viewer === this.black));
    },
    viewerIsInvited: function () {
      return (this.gameState === GameState.New) &&
          (this.viewer !== NULL_ADDRESS) &&
          (this.viewer === this.white);
    },
    viewerIsPlayer: function () {
      return this.viewer === this.white || this.viewer === this.black;
    },
    parsedFen: function () {
      return parseFen(this.board);
    },
    describeGame: function () {
      return describeGameState(this, this.viewer);
    },
    overtime: function () {
      return (this.now > this.turnExpiresAtDate.valueOf());
    },
    countdown: function () {
      if (this.gameState !== GameState.Live) {
        return null;
      }
      return formatTime(msToTime(this.turnExpiresAtDate.valueOf() - this.now));
    },
    cta: function () {
      const isStarted = (this.board !== initialBoard);
      const isHost = (this.black === this.viewer);
      const isGuest = (this.white === this.viewer);
      const r = {
        label: 'View',
        action: 'view',
        class: 'primary'
      };
      do {
        if (this.gameOver) {
          r.label = isStarted ? 'Game Over' : 'Canceled';
          r.action = null;
          r.class = 'secondary';
          break;
        }
        if (isHost) {
          if (this.gameState === GameState.New) {
            r.label = 'Cancel';
            r.action = 'cancel';
            r.class = 'red';
          } else {
            r.label = 'Play';
            r.action = 'play';
          }
          break;
        }
        if (isGuest) {
          if (this.gameState === GameState.New) {
            r.label = 'Join';
            r.action = 'join';
          } else {
            r.label = 'Play';
            r.action = 'play';
          }
          break;
        }
        // Not the Host or Reserved Guest
        if (this.gameState === GameState.New) {
          r.label = 'Reserved';
          r.action = null;
          r.class = 'amber accent-4';
          break;
        }
        if (this.white === NULL_ADDRESS) {
          r.label = 'Join';
          r.action = 'join';
          break;
        }
      } while (0);
      return r;
    },
    amountETH: function () {
      return fromWei(this.amount);
    }
  },
  data () {
    return {
      now: Date.now(),
      timer: null
    };
  },
  beforeCreate () {
    this.timer = setInterval(() => {
      this.now = Date.now();
    }, 1000);
  },
  beforeDestroy () {
    clearInterval(this.timer);
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/css/variables.sass'
.join-game__wrap
  background-color: $app-accent

.gamecard
  width: 20.125rem
  border: 0.5rem solid $app-accent

.gamecard.viewer-is-invited
  border: 0.5rem solid $app-accent
  background-color: $app-accent

.gamecard.my-turn
  border: 0.5rem solid white
  background-color: white
  color: black

.gamecard .header-wrap,
.gamecard .board-wrap
  color: white
  background-color: $app-background

.gamecard .board-container
  display: block
  position: relative
  width: 17rem
  height: 17rem
  margin: auto

.gamecard .flat-line
  line-height: 2
  width: 100%

.gamecard .host-label
  background-color: black
  color: white

.gamecard .guest-label
  border: 1px solid black

.gamecard .host-label .selected
  text-decoration: underline
  text-decoration-thickness: 0.1rem
  text-decoration-color: white

.gamecard .guest-label .selected
  text-decoration: underline
  text-decoration-thickness: 0.1rem
  text-decoration-color: black

.gamecard .countdown span
  min-height: 1.5rem
  width: 8rem
  text-align: center

.gamecard .countdown .overtime
  background-color: $app-accent-dark
  border-radius: 0.5rem
  color: rgba(255,0,0,0.8)
//
//.gamecard ..selected
//  box-shadow: 0 0.3rem #4287f5
//
//
</style>
<style>
/*
 * Most of these styles were required to override
 * effects on the chess board component when rendered
 * within a vuetify v-app container
 *
 */
.gamecard .board-container .cg-board-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
}
.gamecard .board-container .cg-board-wrap .black {
  background-color: rgba(0,0,0,0) !important;
  border-color: rgba(0,0,0,0) !important;
}

.gamecard .board-container .cg-board-wrap .white {
  background-color: rgba(0,0,0,0) !important;
  border-color: rgba(0,0,0,0) !important;
}

.gamecard .board-container .blue {
  background-color: rgba(0,0,0,0) !important;
}
</style>
