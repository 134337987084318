<template>
  <v-container fluid class="pt-0">
   <page-header :amount="null" />
    <h1>Club Rules</h1>
    <p>
    Welcome to the Club.  Every game you play and every move you make is recorded in an Ethereum block chain transaction.  Impress your friends, family and social media followers with your chess skills that will live forever on the chain!
    </p>
    <section rules="game-start">
      <h2>Starting a Game</h2>
      <p>
      You can start a game at any time.  The system may enforce a minimum Table Stake (see below), the current is 0 ETH. <em>Important:</em> Even if there is no Table Stake attached to your game, it is not entirely free. See section on fees below.
      </p>
      <h3> Required Parameters</h3>
      <ul>
        <li>Table Stakes - The amount of Ether you would like to stake on the Game. In order for an opponent to join your game they will have to stake an equal amount.</li>
      </ul>
      <h3>Optional Parameters</h3>
      <ul>
        <li>Reserve for Opponent - If you would like to reserve the game for a particular opponent, enter their account address here.  Only a player with that address will be able to join your game.</li>
        <li>Turn Expiration - You can selected the amount of hours or days you will allow for your game before a player can be considered in forfeit for not taking their turn.  When a player is in forfeit their opponent can cancel the game at any time and receive the entire table stake.  If this is left unset, the system default is 24 hours.</li>
      </ul>
    </section>
    <section id="rules-join">
      <h2>Joining a game</h2>
      <p>You can join any open game, or games that have been reserved for your Etherium account address.  If you are using a browser that enables multiple accounts, you may need to switch accounts to access a game reserved for you.</p>
      <p><em>Important: </em>Even if you are joining a game with no Table Stake you may incur costs just for playing.  See section on fees below.</p>
    </section>
    <section id="rules-cancel">
      <h2>Ending a Game Early</h2>
      <p>A game can be ended at anytime, but you should be aware of the consequences.</p>
      <h3>Ending a game before it starts</h3>
      <p>If you create a game with a Table Stake and it is not started you can end your game and receive a full refund.  If you cancel before 24 hours you will receive your Stake back minus the Game Fee.</p>
      <h3>Ending a Live Game</h3>
      <p>A game is considered live after it has been joined by an opponent.  At that point, what happens when a game is ended is determined based on who ended the game - the current turn player, or the waiting player - and whether or not the turn timeout limit expired.</p>
      <h4>Current Turn Player Cancels</h4>
      <p>This will be interpreted as a forfeit.  All funds will be sent to the other player, less any associated Game Fee.</p>
      <h4>Waiting Player Cancels</h4>
      <p>If the Turn Timeout has not yet expired, this will be interpeted as a forfeit and all funds will be sent to the opponent, less the Game Fee.</p>
      <p>If the Turn Timeout has expired, this will be interpreted as a delay of game - ie a forfeit - and the player who cancels will receive all the funds, less the game fee.</p>
      <table class="table mb-8">
        <thead>
          <tr>
            <th :colspan="endgameHeaders.length" >
              End Game Rules for Distributing Funds
            </th>
          </tr>
          <tr>
            <th class="cell text-center pl-1 pr-1 pl-sm-4 pr-sm-4"
              v-for="(col, idx) of endgameHeaders" :key="`item-${idx}`">
                {{col}}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, idx) of endgameRules" :key="`item-${idx}`">
            <td class="cell text-center" v-for="(col, idx) of row" :key="`col-${idx}`">
              {{col}}
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <section id="rules-fees">
      <h2>Fees</h2>
      <p><em>Warning! Playing can cost you ETH!</em></p>
      <p>Some of the fees are specific to the game, some are inherent to playing a game where every move is stored on a blockchain.</p>
      <h3>Game Costs</h3>
      <p>These are potential costs that can be incurred based on the parameters of your game.</p>
      <ul>
        <li>Table Stake - This is the amount the creator of the game has set as a requirement to play.  Any ETH you send to the game may be lost if you lose, or forfeit (explicitly or through inaction).</li>
        <li>Game Fee - This would be a percentage of the Table Stake kept by the house.</li>
      </ul>
      <br />
      <h3>Blockchain Costs</h3>
      <p>These are costs incurred for adding data to the block chain itself. This is called <a target="_blank" href="https://www.investopedia.com/terms/g/gas-ethereum.asp"><em>Gas</em></a>. The amount of gas required for playing are low, and you should be able to set the price you are willing to pay via your browser (Metamask).</p>
      <p>Note: the gas price you are willing to pay will have some impact on how long it takes your actions to be recorded on the chain and to show up in the Club. Your browser should pop up a confirm anytime gas is required for an interaction.</p>
      <p><strong>Actions that require Gas: </strong></p>
      <ul>
        <li>Creating a game</li>
        <li>Joining a game</li>
        <li>Ending a game</li>
        <li>Committing a move</li>
      </ul>
      <br />
      <p>There is <strong>no cost</strong> for simply viewing games.</p>
    </section>
  </v-container>
</template>
<script>
import PageHeader from '@/components/PageHeader';

export default {
  name: 'Rules',
  components: { PageHeader },
  data: function () {
    return {
      endgameHeaders: [
        'Ended By', 'Turn', 'Game is Live', 'Time Limit Expired', 'Funds go to', 'Game Fee Charged'
      ],
      endgameRules: [
        ['Host', 'N/A', 'No', 'No', 'Host', 'Yes'],
        ['Host', 'N/A', 'No', 'Yes', 'Host', 'No'],
        ['Guest', 'Guest', 'Yes', 'No', 'Host', 'Yes'],
        ['Host', 'Host', 'Yes', 'No', 'Guest', 'Yes'],
        ['Host', 'Guest', 'Yes', 'No', 'Guest', 'Yes'],
        ['Guest', 'Host', 'Yes', 'No', 'Host', 'Yes'],
        ['Host', 'Guest', 'Yes', 'Yes', 'Host', 'Yes'],
        ['Guest', 'Host', 'Yes', 'Yes', 'Guest', 'Yes']
      ]
    };
  }
};
</script>
<style scoped>
  .table {
    border-collapse: collapse;
    border: 2px solid white;
  }
  .table .cell {
    border: 2px solid white;
    font-family: RobotoMono !important;
    font-size: 0.75rem;
    color: white;
  }

  * {
    font-family: RobotoMono;
    color: black;
  }
  h1  {
    font-family: minecraft;
    color: white;
  }
  h2, h3, h4, th {
    color: white;
  }
  @media only screen and (min-width: 768px) {
    .table .cell {
      font-size: 1rem;
    }
  }

</style>
