import Web3 from 'web3';
import { NULL_ADDRESS } from '@/lib/ether';
import { parseFen, GameState, GameTurn } from '@/lib/chess';
import chains from './chains.js';
import contracts from './contractdb.json';
import router from '@/router';
import namehash from 'eth-ens-namehash';

const ReverseRecordsAbi = [
  {
    inputs:[{
      internalType:'contract ENS',
      name:'_ens',
      type:'address'
    }],
    stateMutability:'nonpayable',
    type:'constructor'
  },
  {
    inputs:[{
      internalType:'address[]',
      name:'addresses',
      type:'address[]'
    }],
    name:'getNames',
    outputs:[{
      internalType:'string[]',
      name:'r',
      type:'string[]'
    }],
    stateMutability:'view',
    type:'function'
  }
];

let web3 = null;

const availableNetworks = Object.keys(chains).map(v => Number(v));
const validateEndpoint = '/api/bccc/v1/validate';

const isIOS = /iPad|iPhone|iPod/.test(navigator.platform)
  || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1);

const sortByTime = (a, b) => {
  return a.updatedAt - b.updatedAt > 0 ? -1 : 1;
};

const sortByStatusAndTime = (a, b) => {
  if ((a.gameState === GameState.Live) && (b.gameState !== GameState.Live)) {
    return -1;
  }
  if ((a.gameState !== GameState.Live) && (b.gameState === GameState.Live)) {
    return 1;
  }
  return sortByTime(a, b);
};

const initWeb3FromRPC = (networkId) => {
  console.log('initWeb3FromRPC', networkId);
  const chain = chains[networkId];
  let _web3;

  if (isIOS) {
    // iPad or iPhone
    console.log('On safari, use http provider.');
    if (chain.rpc?.http) {
      _web3 = new Web3(new Web3.providers.HttpProvider(chain.rpc.http));
    }
  } else {
    if (chain.rpc?.ws) {
      console.log('Use websocket provider.');
      _web3 = new Web3(new Web3.providers.WebsocketProvider(chain.rpc.ws));
    } else
    if (chain.rpc?.http) {
      _web3 = new Web3(new Web3.providers.HttpProvider(chain.rpc.http));
    }
  }
  return _web3;
};

const toGame = (id, data) => {
  const game = { id, hasTrophy: null };
  for (const fld of [
    'amount', 'gameFee', 'black', 'board', 'fundsPaid',
    'white', 'winner', 'canceledBy'
  ]) {
    game[fld] = data[fld];
  }

  for (const fld of [
    'createdAt', 'updatedAt', 'gameState', 'turnTimeout'
  ]) {
    game[fld] = parseInt(data[fld]);
  }
  const [lastMoveFrom, lastMoveTo] = (data.lastMove === '0x00000000000000000000000000000000')
    ? ';'.split(';')
    : Buffer.from(data.lastMove.replace(/^0x/, ''), 'hex').toString().split(';');
  game.lastMoveFrom = lastMoveFrom;
  game.lastMoveTo = lastMoveTo.replace(/\0/g, '');

  const turn = parseInt(data.turn);
  if (turn === GameTurn.Black) {
    game.turn = game.black;
  } else
  if (turn === GameTurn.White) {
    game.turn = game.white;
  } else {
    game.turn = NULL_ADDRESS;
  }

  game.boardState = parseFen(game.board);

  if (game.createdAt) {
    game.createdAtDate = new Date(game.createdAt * 1000);
  }
  if (game.updatedAt) {
    game.updatedAtDate = new Date(game.updatedAt * 1000);
  }

  game.gameOver = ((game.gameState !== GameState.New) && (game.gameState !== GameState.Live));

  if (game.gameState === GameState.New) {
    game.turnExpiresAtDate = new Date((game.updatedAt + 86400) * 1000);
  } else {
    game.turnExpiresAtDate = new Date((game.updatedAt + game.turnTimeout) * 1000);
  }
  return game;
};

const _refreshState = async ({ state, dispatch, commit }) => {
  if (state.listener) {
    console.log('unsubscribe old listener', state.listener);
    state.listener.unsubscribe();
  }

  console.log('get Account and Chain');
  const [_accts, chainId, gasPrice] = await Promise.all([
    web3.eth.getAccounts(),
    web3.eth.getChainId(),
    web3.eth.getGasPrice()
  ]);
  console.log('got', { _accts, chainId, gasPrice });

  const chain = chains[chainId];
  const account = _accts[0];
  let balance = 0;

  console.log('chain:', chain);
  if (!state.publicMode) {
    if (!account) {
      throw new Error(`Unable to find account on network ${chainId}.`);
    }

    balance = await web3.eth.getBalance(_accts[0]);
  }

  let contract = null; let tokenContract = null; let listener = null; let _games = []; const games = new Map();
  let gasCreateGame = null; let minExpire = null; let maxExpire = null; let gameFeePct;
  let ensReverseContract = null;
  let names = (state.chain?.chainId === chainId)
    ? state.names
    : new Map();

  const { abi, networks } = state.chessContractMetadata;
  const net = availableNetworks.includes(chainId) && networks[chainId];
  console.log('get Contracts', { chainId, availableNetworks, networks, net });

  if (net) {
    console.log(`get Code at ${net.address}`);
    const code = await web3.eth.getCode(net.address);
    if (code !== '0x') {
      console.log(`get Contract at ${net.address}`);
      contract = new web3.eth.Contract(abi, net.address);
    } else {
      throw new Error(`No contract at address: ${chainId}:${net.address}.`);
    }
  } else {
    const data = {
      account,
      balance,
      chainId,
      chain,
      games: [],
      names,
      contract: null,
      tokenContract: null,
      ensReverseContract: null,
      listener: null,
      gasCreateGame: null,
      gasPrice: null,
      minExpire: null,
      maxExpire: null,
      gameFeePct: null,
      publicMode: (!window.ethereum)
    };
    commit('refresh', data);
    if (chains[chainId]) {
      throw new Error(`There is no Club on the ${chains[chainId].network} network.`);
    }
    throw new Error('There is no Club on this network.');
  }

  if (contract) {
    console.log('subscribe to contract events', { contract });
    listener = contract.events.allEvents({ }, async (err, result) => {
      if (err) {
        console.error('contract events error', err.message);
        commit('setEventsSupport', false);
        return;
      }
      console.log('contract event', result);
      if (result.event === 'GameCreated') {
        const gameId = result.returnValues.gameId;
        dispatch('getGameById', gameId);
      } else
      if (result.event === 'GameCanceled') {
        const gameId = result.returnValues.gameId;
        dispatch('getGameById', gameId);
      } else
      if (result.event === 'GameJoined') {
        const gameId = result.returnValues.gameId;
        dispatch('getGameById', gameId);
      } else
      if (result.event === 'TurnTaken') {
        const gameId = result.returnValues.gameId;
        dispatch('getGameById', gameId);
      }
      dispatch('refreshBalance');
    })
      .on('connected', () => {
        console.log('contract events connected');
        commit('setEventsSupport', true);
      })
      .on('changed', data => {
        console.log('contract changed', data);
      });

    console.log('get games');
    try {
      const r = await Promise.all([
        contract.methods.getGames(100, 0).call(),
        contract.methods.createGame(NULL_ADDRESS, 0).estimateGas({ value: '0' }),
        contract.methods.minExpire().call(),
        contract.methods.maxExpire().call(),
        contract.methods.gameFeePct().call()
      ]);
      _games = r[0];
      gasCreateGame = r[1];
      minExpire = r[2];
      maxExpire = r[3];
      gameFeePct = r[4];
    } catch (err) {
      console.error(err.message);
    }
  }
  console.log('_games:', _games);
  for (let gameId = 0; gameId < _games.length; gameId++) {
    games.set(gameId.toString(), toGame(gameId.toString(), _games[gameId]));
  }

  // Load Token Contract
  try {
    const { abi, networks } = state.tokenContractMetadata;
    const net = availableNetworks.includes(chainId) && networks[chainId];
    if (net) {
      console.log(`get Token Code at ${net.address}`);
      const code = await web3.eth.getCode(net.address);
      if (code !== '0x') {
        console.log(`get Token Contract at ${net.address}`);
        tokenContract = new web3.eth.Contract(abi, net.address);
      } else {
        throw new Error(`No token contract at address: ${chainId}:${net.address}.`);
      }
    }     
  }
  catch (error) {
    console.error(error);
  }

  // Load ENS Reverse Lookup Contract
  try {
    if (chain.ens?.reverseRecordsAddress) {
      console.log(`get ENS Reverse Code at ${chain.ens.reverseRecordsAddress}`);
      ensReverseContract = new  web3.eth.Contract(ReverseRecordsAbi, chain.ens.reverseRecordsAddress);
    }
  }
  catch (error) {
    console.error(error);
  }
  const data = {
    account,
    balance,
    chainId,
    chain,
    contract,
    tokenContract,
    ensReverseContract,
    listener,
    games,
    names,
    gasCreateGame,
    gasPrice,
    minExpire,
    maxExpire,
    gameFeePct,
    publicMode: (!window.ethereum)
  };

  commit('refresh', data);

  return data;
};

const ensureContract = (state) => {
  if (!state.connected) {
    throw new Error('Not connected to blockchain.');
  }
  if (!state.contract) {
    throw new Error('Unable to locate contract.');
  }
  return state.contract;
};

const ensureTokenContract = (state) => {
  if (!state.connected) {
    throw new Error('Not connected to blockchain.');
  }
  if (!state.tokenContract) {
    throw new Error('Unable to locate contract.');
  }
  return state.tokenContract;
};

const initializeState = (state = {}) => {
  state.lastRefresh = null;
  state.refreshing = false;
  state.connected = false;
  state.publicMode = (!window.ethereum);
  state.eventsSupport = null;
  state.account = null;
  state.balance = null;
  state.chainId = null;
  state.chain = null;
  state.contract = null;
  state.tokenContract = null;
  state.ensReverseContract = null;
  state.listener = null;
  state.games = new Map();
  state.names = new Map();
  state.gasCreateGame = null;
  state.gasPrice = null;
  state.minExpire = null;
  state.maxExpire = null;
  state.gameFeePct = null;
  state.chessContractMetadata = null;
  state.tokenContractMetadata = null;
  return state;
};

const hostChain = () => {
  const host = location.hostname;
  let network = '';
  if ((host === '127.0.0.1') || (host === 'localhost')) {
    network = 'localhost';
  }
  else {
    network = host.split('.')[0];
  }
  console.log('lookup network', { host, network });
  const chain = Object.values(chains).find(chain => (chain.network === network));
  return chain || null;
};

export default {
  namespaced: true,
  state: initializeState(),
  getters: {
    available: () => {
      return (!!window.ethereum) || (!!chains[availableNetworks[0]]?.rpc);
    },
    hostChain,
    currentChain: state => (state.chain),
    ready: state => (state.connected && !!state.contract),
    openGames: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if (game.gameOver) {
          continue;
        }
        if (game.black === state.account) {
          continue;
        }
        if (game.white !== NULL_ADDRESS) {
          continue;
        }
        r.push(game);
      }
      return r.sort(sortByTime);
    },
    myGamesHost: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if (!game.gameOver && (game.black === state.account)) {
          r.push(game);
        }
      }
      return r.sort(sortByTime);
    },
    myGamesGuest: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if (!game.gameOver && (game.white === state.account)) {
          r.push(game);
        }
      }
      return r.sort(sortByTime);
    },
    myCurrGames: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if (!game.gameOver &&
          ((game.white === state.account) || (game.black === state.account))
        ) {
          r.push(game);
        }
      }
      return r.sort(sortByTime);
    },
    myPastGames: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if (game.gameOver &&
          ((game.white === state.account) || (game.black === state.account)) &&
          ((game.boardState.fullMove > 1) || (game.gameState === GameState.Forfeit))
        ) {
          r.push(game);
        }
      }
      return r.sort(sortByTime);
    },
    liveGames: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if ((game.gameState === GameState.Live) &&
          ((game.white !== state.account) && (game.black !== state.account))
        ) {
          r.push(game);
        }
      }
      return r.sort(sortByTime);
    },
    memberStatus: (state, getters) => {
      let status = 'Provisional Member';
      if (state.publicMode) {
        status = 'Guest';
      } else
      if (getters.myCurrGames.length) {
        status = 'Active Member'
        ;
      } else
      if (getters.myPastGames.length) {
        status = 'Inactive Member';
      }
      return status;
    },
    recentGames: (state) => {
      const r = [];
      for (const game of state.games.values()) {
        if ((game.gameState !== GameState.New) &&
          ((game.white !== state.account) && (game.black !== state.account))
        ) {
          r.push(game);
        }
      }
      return r.sort(sortByStatusAndTime);
    },
    contractWebAddress: (state) => {
      const chain = chains[state.chainId];
      const url = chain?.explorer?.url;
      if (!url) {
        return null;
      }
      const { networks } = state.chessContractMetadata;
      const net = networks[state.chainId];
      if (net) {
        return `${url}/address/${net.address}#code`;
      }
      return null;
    },
    tokenContractAddress: (state) => {
      const chain = chains[state.chainId];
      const url = chain?.explorer?.url;
      if (!url) {
        return null;
      }
      const { networks } = state.tokenContractMetadata;
      const net = networks[state.chainId];
      if (net) {
        return `${url}/address/${net.address}#code`;
      }
      return null;
    },
    tokenListingAddress: (state) => {
      const chain = chains[state.chainId];
      const url = chain?.explorer?.url;
      if (!url) {
        return null;
      }
      const { networks } = state.tokenContractMetadata;
      const net = networks[state.chainId];
      if (net) {
        return `${url}/token/${net.address}`;
      }
      return null;
    },
    publicTestChains: () => {
      return Object.values(chains).filter(c => c.publicTestChain);
    } 
  },
  mutations: {
    setAddrNames (state, v) {
      v.forEach((value, key) => {
        state.names.set(key, value);
      });
    },
    setEventsSupport (state, v) {
      state.eventsSupport = v;
    },
    setBalance (state, balance) {
      state.balance = balance;
    },
    setConnected (state, connected) {
      state.connected = connected;
    },
    setContractsMetadata (state, { chess, token }) {
      state.chessContractMetadata = chess;
      state.tokenContractMetadata = token;
    },
    setGasCreateGame (state, gas) {
      state.gasCreateGame = gas;
    },
    setGasPrice (state, price) {
      state.gasPrice = web3.utils.toBN(price);
    },
    setGame (state, game) {
      const games = new Map();
      for (const [key, value] of state.games.entries()) {
        games.set(key, value);
      }
      games.set(game.id, game);
      state.games = games;
    },
    setRefreshing (state, v) {
      state.refreshing = v;
    },
    clear (state) {
      initializeState(state);
    },
    refresh (state, data) {
      state.lastRefresh = new Date();
      state.refreshing = false;
      state.account = data.account;
      state.balance = data.balance;
      state.chainId = data.chainId;
      state.chain = data.chain;
      state.contract = data.contract;
      state.tokenContract = data.tokenContract;
      state.ensReverseContract = data.ensReverseContract;
      state.listener = data.listener;
      state.games = data.games;
      state.names = data.names;
      state.gasCreateGame = data.gasCreateGame;
      state.gasPrice = data.gasPrice ? web3.utils.toBN(data.gasPrice) : null;
      state.minExpire = (data.minExpire !== null) ? Number(data.minExpire) : null;
      state.maxExpire = (data.maxExpire !== null) ? Number(data.maxExpire) : null;
      state.gameFeePct = (data.gameFeePct !== null) ? Number(data.gameFeePct) : null;
    }
  },
  actions: {
    async getBrowserNetwork() {
      if (!web3) {
        return null;
      }
      const chainId = await web3.eth.net.getId();
      return chains[chainId] || null;
    },
    async getContractMetadata ({ commit }) {
      try {
        commit('setContractsMetadata', {
          chess: contracts.chess,
          token: contracts.chesstoken
        });
      } catch (err) {
        console.error(err);
      }
    },
    async addressToName ({ state, commit }, addresses) {
      const results = new Map();
      const lookups = [];
      
      for (const addr of addresses) {
        const name = state.names.get(addr);
        results.set(addr, name || addr);
        if (!name) {
          lookups.push(addr);
        }
      }
      console.log(' addressToName', { lookups, contract: state.ensReverseContract });
      if (lookups.length && state.ensReverseContract) {
        try {
          const names = await state.ensReverseContract.methods.getNames(lookups).call();
          console.log('NAMES:', names);
          for (let i = 0; i < lookups.length; i++) {
            if (names[i]) {
              results.set(lookups[i], namehash.normalize(names[i]));
            }
          }
        }
        catch(error) {
          console.error('eNS FAIL', error);
        }
      }

      commit('setAddrNames', results);

      return results;
    },
    async connect ({ state, commit, getters, dispatch }) {
      console.log('call connect, state.connected=', state.connected);
      if (!getters.available) {
        throw new Error('Ethereum is not supported in this browser.');
      }

      if (state.connected) {
        return;
      }

      if (window.ethereum) {
        try {
          if (window.ethereum.request) {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
          } else {
            await window.ethereum.enable();
          }
        } catch (err) {
          if (err.code !== 4001) {
            throw err;
          }
          return;
        }
        web3 = new Web3(window.ethereum);
      } else {
        console.log('init with backup');
        web3 = initWeb3FromRPC(getters.hostChain?.networkId || 1);
      }
      if (!web3) {
        throw new Error('Unable to connect to Ethereum.');
      }
      // commit('setConnected', true);
      console.log('window.ethereum:', window.ethereum);
      const emitter = window.ethereum?.on
        ? window.ethereum
        : web3.eth.subscribe('logs');

      emitter.on('accountsChanged', async accounts => {
        console.log('accountChanged', accounts);
        try {
          await dispatch('refreshState');
        } catch (e) {
          console.error('accountsChanged: refreshState fails', e.message);
        }
      });

      emitter.on('chainChanged', async chainId => {
        const hostChainId = hostChain()?.chainId;
        console.log('chainChanged', { chainId: Number(chainId), hostChainId });
        if (hostChainId !== Number(chainId)) {
          console.log('redirect to 409!')
          return router.push('/409');
        }
        try {
          await dispatch('refreshState');
          return router.push('/');
        } catch (e) {
          console.error('chainChanged: refreshState fails', e.message);
        }
      });

      const r = await dispatch('refreshState');
      commit('setConnected', true);
      return r;
    },
    async createGame ({ state, dispatch }, payload) {
      if (state.publicMode) {
        throw new Error('Cannot create games without a full ethereum connection.');
      }
      console.log('payload', payload);
      const contract = ensureContract(state);
      const turnTimeout = payload.turnTimeout || 0;
      const value = payload.amount;
      const gasPrice = payload.gasPrice;
      const addrWhite = payload.addressWhite || NULL_ADDRESS;

      const r = await contract.methods
        .createGame(addrWhite, turnTimeout)
        .send({ from: state.account, gasPrice, value });
      console.log('create game returns', r);
      if (!state.eventsSupport) {
        const gameId = r?.events?.GameCreated?.returnValues?.gameId;
        console.log('found gameId:', gameId);
        if (gameId) {
          dispatch('getGameById', gameId);
        }
      }
      return r;
    },
    async joinGame ({ state, dispatch }, gameId) {
      if (state.publicMode) {
        throw new Error('Cannot join games without a full ethereum connection.');
      }
      const contract = ensureContract(state);
      const game = state.games.get(gameId);
      const opts = {
        from: state.account
      };
      if (game.amount) {
        opts.value = game.amount;
      }
      const r = await contract.methods
        .joinGame(gameId)
        .send(opts);

      console.log('join game returns', r);
      if (!state.eventsSupport) {
        dispatch('getGameById', gameId);
      }
      return r;
    },
    async cancelGame ({ state, dispatch }, gameId) {
      const contract = ensureContract(state);
      const r = await contract.methods
        .cancelGame(gameId)
        .send({ from: state.account });

      console.log('cancel game returns', r);
      if (!state.eventsSupport) {
        dispatch('getGameById', gameId);
      }
      return r;
    },
    async validateMove (context, { board, move }) {
      const r = await fetch(validateEndpoint, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ board, move })
      });
      const body = await r.json();
      if (r.status === 200) {
        return body.message;
      } else
      if (r.status === 400) {
        throw new Error(body.message);
      }
      throw new Error('Something went wrong.');
    },
    async makeMove ({ state, dispatch }, { game, pendingMove }) {
      const contract = ensureContract(state);
      const { lastMove, outputBoard, gameState, data } = pendingMove.params;
      const r = await contract.methods.makeMove(
        game.id, lastMove, outputBoard, gameState, data, pendingMove.signature
      ).send({ from: state.account });
      console.log('makeMove response', r);
      if (!state.eventsSupport) {
        dispatch('getGameById', game.id);
      }
      return r;
    },
    async claimTrophy({ state, dispatch }, gameId) {
      const contract = ensureTokenContract(state);
      const r = await contract.methods.claim(gameId).send({ from: state.account });
      console.log('claimTrophy returns', r);
      dispatch('getGameById', gameId);
      return r;
    },
    async checkTrophy({ state }, gameId) {
      const contract = ensureTokenContract(state);
      try {
        await contract.methods.ownerOf(gameId).call();
        return true;
      }
      catch (error) {
        if (!error.message.match(/owner query for nonexistent token/)) {
          console.error(error);
        }
      }
      return false;
    },
    async estCostCreateGame ({ state, commit }, payload = {}) {
      const contract = ensureContract(state);
      const turnTimeout = payload.turnTimeout || 0;
      const value = web3.utils.toWei((payload.stake || 0).toString());

      const gas = (await contract.methods.createGame(
        NULL_ADDRESS, turnTimeout).estimateGas({ value })).toString();

      commit('setGasCreateGame', gas);
      return gas;
    },
    async getGasPrice ({ commit }) {
      const price = await web3.eth.getGasPrice();
      commit('setGasPrice', price);
      return price;
    },
    async refreshBalance ({ state, commit }) {
      if (state.account) {
        const balance = await web3.eth.getBalance(state.account);
        commit('setBalance', balance);
      }
    },
    async getGameById ({ state, commit, dispatch }, gameId) {
      const contract = ensureContract(state);
      const game = toGame(gameId.toString(), await contract.methods.getGameById(gameId).call());
      if (game.gameState === GameState.CheckMate) {
        game.hasTrophy = await dispatch('checkTrophy', gameId);
      }
      commit('setGame', game);
    },
    async refreshState ({ state, dispatch, commit }) {
      if (state.refreshing) {
        console.log('already refreshing, bail.');
        return;
      }
      commit('setRefreshing', true);
      let r;
      try {
        r = await _refreshState({ state, dispatch, commit });
      } catch (e) {
        console.log('caught refresh error');
        commit('setRefreshing', false);
        throw e;
      }
      return r;
    }
  }
};
