<template>
  <div class="page-header d-flex px-7 py-6 pt-sm-11 justify-space-between">
    <div class="d-flex d-flex-grow-1">
      <router-link to="/" class="d-flex align-center">
        <img src="@/assets/img/back-button.svg" alt="back-button" />
      </router-link>
    </div>
    <div v-if="amount !== null" class="d-flex">
      <img src="@/assets/img/ether.svg" alt="ether-symbol" width="20"/>
      <span class="d-inline-block pa-2" style="font-family: Roboto !important; line-height: 1.25">
        {{ amount | fromWei | round}} ETH</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'PageHeader',
  props: {
    amount: String
  }
};
</script>
