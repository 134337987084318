/* eslint-disable no-new */

import Vue from 'vue';
import App from './App.vue';
import store from './store';
import router from './router';
import vuetify from './plugins/vuetify';
import './filters';
import './assets/css/main.sass';

Vue.config.productionTip = false;

new Vue({
  el: '#app',
  router,
  store,
  template: '<App />',
  vuetify,

  // render: h => h(App),
  components: { App }
});
