<script>
import { chessboard } from 'vue-chessboard';
import './vue-chessboard.css';
export default {
  name: 'Chessboard',
  extends: chessboard,
  props: {
    cursor: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    enableSide: {
      type: String,
      default: null,
      required: false,
      validator: function (value) {
        return (value === null) ||
                  (value === undefined) ||
                  (value === 'b') ||
                  (value === 'w');
      }
    }
  },
  data: function () {
    return {
    };
  },
  computed: { },
  watch: {
    enableSide: function () {
      this.loadPosition(true);
    }
  },
  methods: {
    shouldDisable () {
      let disabled = this.disabled;
      if (!disabled) {
        if (this.enableSide) {
          if (this.game.turn() !== this.enableSide) {
            disabled = true;
          }
        }
      }
      return disabled;
    },
    _checkBoard () {
      let shouldDisable = this.shouldDisable();
      // Need this extra check to prevent the player from moving
      // the piece of the opponent after taking their move, but before
      // clicking on submit.  The to fen's compared here are not
      // reactive, so putting this check within the shouldDisable
      // computed property doesn't work.
      if (this.fen !== this.game.fen()) {
        shouldDisable = true;
      }

      const $board = this.$refs.board.querySelector('.cg-board');
      if ($board && !this.cursor) {
        $board.style.cursor = (shouldDisable)
          ? 'default'
          : 'pointer';
      }

      this.board.set({
        viewOnly: shouldDisable,
        movable: {
          showDests: true
        },
        highlight: {
          lastMove: false
        },
        draggable: {
          showGhost: false
        },
        drawable: {
          pieces: {
            baseUrl: '/img/cg-pieces/'
          }
        }
      });
    },
    loadPosition (checkboard = true) {
      try {
        chessboard.methods.loadPosition.call(this);
        if (checkboard) {
          this._checkBoard();
        }
      } catch (e) {
        // do nothing
      }
    },
    redrawAll () {
      this.board.redrawAll();
    }
  },
  unmounted () {
    window.removeEventListener('resize', this.redrawAll);
  },
  mounted () {
    this.$on('onMove', () => {
      this._checkBoard();
    });

    window.addEventListener('resize', this.redrawAll);
  }
};
</script>
<style >
  .cg-board .hidden {
    display: none;
  }

</style>
