import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);
export const options = {
  theme: {
    light: true,
    themes: {
      light: {
        accent: '#2F5789',
        color: '#ffffff',
        background: '#447AC8'
      }
    }
  }
};
export default new Vuetify(options);
