<template>
  <div class="d-flex flex-column justify-center background-highlight" style="height: 100%;">
    <div class="d-flex justify-center flex-column">
      <img class="d-block mx-auto" src="@/assets/img/logo.svg" alt="logo" width="181"/>
      <div class="mx-auto mt-8 pa-8" style="font-size: 1.5rem">
        Sorry, it looks like your Wallet is pointed at the wrong network. 
        <span v-if="target">
          Either switch it to the {{desiredChain.name}} Network (Id = {{desiredChain.networkId}}) or visit <a :href="target">our club</a> on that network instead.
        </span>
        <span v-else-if="currentChain">
          Try changing back to the {{currentChain.name}} Network (Id = {{currentChain.networkId}}) in your Wallet.
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'E409',
  components: {},
  computed: {
    ...mapGetters('chain', ['currentChain', 'hostChain']),
    target: function () {
      if (!this.desiredChain) {
        return null;
      }
      return this.desiredChain.club;
    }
  },
  props: {
  },
  data: function () {
    return {
      desiredChain: null
    };
  },
  methods: {
  },
  async mounted () {
    this.desiredChain = await this.$store.dispatch('chain/getBrowserNetwork');
    console.log('E409 mounted', {
      desiredChain: this.desiredChain,
      currentChain: this.currentChain,
      hostChain: this.hostChain
    });
    if ((this.desiredChain === this.currentChain) && (this.currentChain === this.hostChain)) {
      this.$router.push('/');
    }
  }
};

</script>
<style scoped>
  .background-highlight {
    background: radial-gradient(57.91% 57.91% at 48.54% 42.09%, #4C9CD6 0%, #4479CA 48.44%);
  }
</style>
